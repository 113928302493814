import React, { useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { useColorContext } from '../ColorContext';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled container using MUI
const StyledContainer = styled(Container)(({ theme }) => ({
  height: '88vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SceneContent = () => {
  const { camera } = useThree();
  const {
    selectedArchIndex,
    selectedColorGetIndex,
    selectedbackcolor,
    selectedHandleIndex,
    selectedGrillIndex,
    selectedbackfront,
    typeOfDoor,
    sideLightGrill,
    selectedArchDoorIndex,
    archunit,
    archdoor1,
  } = useColorContext();

  const [modelPath, setModelPath] = useState('../assets/Wrought_iron_door.gltf');

  useEffect(() => {
    // Logic to set the modelPath based on the archunit value
    const newModelPath = archunit === "true" ? '../assets/Wrought_iron_door.gltf' : '../assets/Wrought_ron_v05.gltf';
    setModelPath(newModelPath);
  }, [archunit]); // Dependency array ensures this runs when 'archunit' changes


 


  // Load the model with useGLTF
  const { scene, nodes, materials } = useGLTF(modelPath);


  console.log("---00--->>",scene)

 
  // State to track if the model is loaded
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  // Set the model as loaded once it is available         
  useEffect(() => {
    if (scene) {
      setIsModelLoaded(true);
    }
  }, [scene]);

  // Resize object
  const [cameraSettings, setCameraSettings] = useState({
    fov: 2,
    position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
  });

  const updateCameraSettings = () => {
    if (window.innerWidth < 800) {
      setCameraSettings({
        fov: 2.5,
        position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
      });
    } else {
      setCameraSettings({
        fov: 1.2,
        position: [-0.1, 1, 5] as [number, number, number], // Ensure correct tuple type
      });
    }
  };

  useEffect(() => {
    updateCameraSettings();
    window.addEventListener('resize', updateCameraSettings);

    return () => {
      window.removeEventListener('resize', updateCameraSettings);
    };
  }, []);

 console.log("-------<><><>",archunit)

  useEffect(() => {
    if (scene) {
      scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {


          if (archunit == 'true') {

         


            if (child.name.includes(`door_${selectedArchDoorIndex + 1}`) || child.name.includes(`Glass_${selectedArchDoorIndex + 1}`) || child.name.includes(`Handle_${selectedGrillIndex + 1}`) || child.name.includes(`grill_${selectedArchDoorIndex + 1}`) || child.name.includes(`Frame_0${selectedArchDoorIndex + 1}`)) {
              child.visible = true;
            }



            else if (child.name.includes("Handle_") || child.name.includes("grill") || child.name.includes("door_") || child.name.includes("Frame") || child.name.includes("Glass_")) {
              child.visible = false;
            }

            if (child.name.includes(`door_${selectedArchDoorIndex + 1}`) || child.name.includes(`Frame_0${selectedArchDoorIndex + 1}`)) {
              child.visible = true;
              child.material.color.set(selectedColorGetIndex ? selectedColorGetIndex : '#4f555c');
              child.material.transparent = true;
            }

          

          }

          else if (archunit=='false'||archunit == undefined ) {

            if (child.name.includes("Arch_") || child.name.includes("Handle_")) {
              child.visible = false;

            } 


            if (child.name.includes(`Arch_${selectedArchIndex + 1}`) || child.name.includes(`Handle_${selectedGrillIndex + 1}`) || child.name.includes("door_") ) {
              child.visible = true;

            }

            if (child.name.includes(`Arch_${selectedArchIndex + 1}`)  || child.name.includes("door_") || child.name.includes("frame") ) {
              //  child.visible = true;
              child.material.color.set(selectedColorGetIndex ? selectedColorGetIndex : '#4f555c');
               child.material.transparent = true;   
             }


          }

  

          // Enable edge sharpening
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });

      scene.position.set(0, 0, 0);
      scene.scale.set(1.5, 1.5, 1.9);
      const boundingBox = new THREE.Box3().setFromObject(scene);
      const center = boundingBox.getCenter(new THREE.Vector3());
      scene.position.set(-center.x - 0.001, -center.y + 0.001, -center.z);
    }
  }, [scene, selectedArchIndex, selectedColorGetIndex, selectedHandleIndex, selectedGrillIndex, selectedbackcolor, sideLightGrill, selectedArchDoorIndex, typeOfDoor,archdoor1,archunit]);

  // Update camera position when model is loaded
  useEffect(() => {
    if (isModelLoaded && scene) {
      const boundingBox = new THREE.Box3().setFromObject(scene);
      const center = boundingBox.getCenter(new THREE.Vector3());
      const cameraOffset = 5;
      camera.position.set(1, -1, 5); // Adjust as needed
      camera.lookAt(center);
    }
  }, [camera, scene, isModelLoaded]);

  // Adjust camera based on selectedbackfront
  const backFront = Number(selectedbackfront);
  if (backFront === 1) {
    camera.position.set(0, 1, -5);
    camera.lookAt(0, 1, 0);
  } else if (backFront === 0) {
    camera.position.set(1, -1, 5);
    camera.lookAt(0, 0, 0);
  }

  if (!isModelLoaded) {
    return null;
  }

  return (
    <>
      <PerspectiveCamera makeDefault fov={cameraSettings.fov} position={cameraSettings.position} />
      <ambientLight intensity={0.5} />
      <spotLight position={[-10, 10, 10]} angle={1.15} penumbra={2} intensity={1} />
      <directionalLight position={[-2, 4, 5]} intensity={3} />
      {scene && <primitive object={scene} />}
      <OrbitControls />
    </>
  );
};

const MyComponent = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledContainer>
      <Box style={{ width: isLargeScreen ? '95%' : 'auto', height: '100%' }}>
        <Canvas shadows>
          <SceneContent />
          <directionalLight position={[5, 5, 5]} intensity={1.5} />
          <directionalLight position={[-5, 5, 5]} intensity={1.5} />
          <directionalLight position={[5, -5, 5]} intensity={1.5} />
          <directionalLight position={[-5, -5, 5]} intensity={1.5} />
          <directionalLight position={[0, 0, -4]} intensity={1.2} />
          <directionalLight position={[0, 5, -4]} intensity={1.2} />
          <directionalLight position={[0, -5, -5]} intensity={1.2} />
          <pointLight position={[0.5, 1, 5]} intensity={1.2} />
        </Canvas>
      </Box>
    </StyledContainer>
  );
};

export default MyComponent;


// is