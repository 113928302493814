import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useColorContext } from "../ColorContext";

// Images import
import Grill1 from "../ColorImages/Grill-1.png";
import Grill2 from "../ColorImages/Grill-2.png";
import Grill3 from "../ColorImages/Grill-3.png";
import handle1 from "../ColorImages/handle-1.png";
import handle2 from "../ColorImages/handle-2.png";
import handle3 from "../ColorImages/handle-3.png";
import handle4 from "../ColorImages/handle-4.png";
import handle5 from "../ColorImages/handle-5.png";

import g1 from "../ColorImages/g1.png";
import g2 from "../ColorImages/g2.png";
import h1 from "../ColorImages/1.png";
import h2 from "../ColorImages/2.png";
import h3 from "../ColorImages/3.png";
import h4 from "../ColorImages/4.png";

import G1 from "../ColorImages/G1(3).png";
import G2 from "../ColorImages/G1(4).png";
import G3 from "../ColorImages/G1(5).png";


import back from "../ColorImages/back.png";
import front from "../ColorImages/front.png";
import { CheckCircle } from "@mui/icons-material";

export default function Handlecom() {


  const { typeOfDoor } = useColorContext() as any;



  const {
    selectedHandleIndex,
    selectedGrillIndex,
    setSelectedHandleIndex,
    setSelectedGrillIndex,
    setSelectedbackfront,
    setSideLightGrill,
  } = useColorContext(); // Destructure the context properly
  const KIT_COLOR = "#5F1C1D";
  const images = [back, front];
  const image = [Grill1, Grill2, Grill3];
  const images2 = [handle1, handle2, handle3, handle4, handle5];

  const handle = [h1, h2, h3, h4];
  const grill = [g1, g2];

  const sidelight = [G1,G2,G3]

  const [imagescript, setImagescript] = useState("");
  const [grillscript, setGrillscript] = useState("");
  const [sidelightscript, setSidelightscript] = useState("");

  const handleGrillClick = (src: any, index: any) => {
    setSelectedGrillIndex(index); // Convert to string                     
    setImagescript(src);
  };

  const handleHandleClick = (src: any, index: any) => {
    setSelectedHandleIndex(index); // Convert to string
    setGrillscript(src);
  };


  const sidelightHandleClick = (src: any, index: any) => {
    setSideLightGrill(index); // Convert to string
    setSidelightscript(src);
  };


  const backfront = (src: any, index: any) => {
    setSelectedbackfront(index); 
  };

  useEffect(() => {
    const storedGrillIndex = localStorage.getItem("selectedGrillIndex");
    const storedHandleIndex = localStorage.getItem("selectedHandleIndex");

    if (storedGrillIndex !== null) {
      setSelectedGrillIndex(storedGrillIndex); // Keep it as string
    }
    if (storedHandleIndex !== null) {
      setSelectedHandleIndex(storedHandleIndex); // Keep it as string
    }
  }, []);

  const handleSpriteClick = async (src: string, index: any) => {
    setImagescript(src);
  };

  return (
    <div>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Handle & Grill
      </Typography>

      {/* <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Back /Front
      </Typography>

      <Grid container spacing={2}>
        {images.map((src, index) => (
          <Grid item xs={4} key={index}>
            <img
              src={src}
              alt={`Image ${index + 2}`}
              style={{ width: "50%", height: "auto", cursor: "pointer" }}
              onClick={() => backfront(src, index)}
            />
          </Grid>
        ))}
      </Grid> */}
 
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Handle
      </Typography>

      <Grid container spacing={2}>
        {handle.map((src, index) => (
          <Grid item xs={4} key={index} style={{ position: "relative" }}>
            {/* Parent div to wrap image and the overlay */}
            <img
              src={src}
              alt={`Image ${index + 2}`}
              style={{ width: "20%", height: "auto", cursor: "pointer" }}
              onClick={() => handleGrillClick(src, index)}
            />

            {/* Conditional rendering of the overlay */}
            {imagescript === src && (
              <div
                style={{
                  position: "absolute",
                  top: "52%",
                  left: "20%",
                  transform: "translate(-50%, -50%)",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              >
                <CheckCircle style={{ color: KIT_COLOR, fontSize: "24px" }} />
              </div>
            )}
          </Grid>
        ))}
      </Grid>

      {/* <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
        Grill
      </Typography> */} 
   
      {/* <Grid container spacing={2}>
        {grill.map((src, index) => (
          <Grid item xs={4} key={index} style={{ position: "relative" }}>
            <img
              src={src}
              alt={`Image ${index + 1}`}
              style={{ width: "77%", height: "auto", cursor: "pointer" }}
              onClick={() => handleHandleClick(src, index)}
            />
 
            {grillscript === src && (
              <div
                style={{
                  position: "absolute",
                  top: "55%",
                  left: "58%",
                  transform: "translate(-50%, -50%)",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              >
                <CheckCircle style={{ color: KIT_COLOR, fontSize: "24px" }} />
              </div>
            )}
          </Grid>
        ))}
      </Grid> */}

{typeOfDoor === "DoubleDoor" ?  

<>
 
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#5F1C1D",
          color: "white",
          pl: 2,
          pt: 0.5,
          pb: 0.5,
          mt: 2,
          mb: 2,
        }}
      >
       SideLights  Grill
      </Typography>

      <Grid container spacing={2}>
        {sidelight.map((src, index) => (
          <Grid item xs={4} key={index} style={{ position: "relative" }}>
            <img
              src={src}
              alt={`Image ${index + 1}`}
              style={{ width: "77%", height: "auto", cursor: "pointer" }}
              onClick={() => sidelightHandleClick(src, index)}
            />

            {sidelightscript === src && (
              <div
                style={{
                  position: "absolute",
                  top: "55%",
                  left: "58%",
                  transform: "translate(-50%, -50%)",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              >
                <CheckCircle style={{ color: KIT_COLOR, fontSize: "24px" }} />
              </div>
            )}
          </Grid>
        ))}
      </Grid>
      </>
: null} 


    </div>
  );
}
