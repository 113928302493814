import React, { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AsyncStorage from '@react-native-async-storage/async-storage';
import "./SpriteStyles.css";
import { useColorContext } from "../ColorContext";
import { CheckCircle } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import A1 from "../ColorImages/A1.png";
import A2 from "../ColorImages/A2.png";
import A3 from "../ColorImages/A3.png";
import A4 from "../ColorImages/A4.png";



import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}

 
const Archcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  const KIT_COLOR = "#5F1C1D";
  const CustomRadio = styled(Radio)({
    color: KIT_COLOR,
    "&.Mui-checked": {
      color: KIT_COLOR,
    },
  });



  ////////////////   door 

  const Arch = [A1, A2, A3, A4];
  const Arch2 = [ A2, A3, A1, A4];

  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [area, setArea] = useState<number | null>(null);
  const [unitCost, setUnitCost] = useState<number | null>(null);

  const [archindex, setArchindex] = useState<number>(0);
  const [archdoorindex, setArchdoorindex] = useState<number>(0);

  useEffect(() => {
    const storedWidth = localStorage.getItem("width");
    const storedHeight = localStorage.getItem("height");
    const storedUnitCost = localStorage.getItem("unitCost");

    if (storedWidth) setWidth(Number(storedWidth));
    if (storedHeight) setHeight(Number(storedHeight));
    if (storedUnitCost) setUnitCost(Number(storedUnitCost));
  }, []);








  /////////////////////// door end





  const [archunitcost, setArchunitcost] = React.useState<any>(null);
  const [archunitcost1, setArchunitcost1] = React.useState<any>(null);
  const [archwidth, setArchwidth] = React.useState<number>();
  const [archwidth1, setArchwidth1] = React.useState<number>();
  const [showInput, setShowInput] = React.useState<any>('');
  const [showInput1, setShowInput1] = React.useState<any>('');
  const [selectedSprite, setSelectedSprite] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading1, setIsLoading1] = React.useState(false);
  const [doorWidth, setDoorWidth] = useState(() => {
    const storedWidth = localStorage.getItem("width");
    return storedWidth ? parseFloat(storedWidth) / 2 : 0; // Default to 0 if No width is found
  });
  const { selectedArchDoorIndex,setSelectedGet,selectedArchIndex, setSelectedArchIndex, setSelectedArchDoorIndex,savedata,setArchunit,setArchdoor1 } = useColorContext () as any;



  useEffect(() => {

    setArchwidth1(doorWidth)
    setArchwidth(doorWidth)

  }, [])


  useEffect(() => {

    if (width !== null && height !== null && unitCost !== null) {
      const calculatedArea = (((width * height) / 144) * unitCost); // Calculate area in square feet
      const areaValue = 0.2 * calculatedArea; // Calculate 20% of the area
      setArea(areaValue);

      if (archunitcost == null) {
        setArchunitcost(areaValue.toFixed(2)); // Update archunitcost with the calculated area
      }

      else if (archunitcost1 == null) {
        setArchunitcost1(areaValue.toFixed(2)); // Update archunitcost with the calculated area
      }

    }

  }, [width, height, unitCost, archunitcost, archunitcost1]);





  React.useEffect(() => {
    const loadValues = async () => {
      try {
        const storedShowInput = await AsyncStorage.getItem('showInput');
        const storedShowInput1 = await AsyncStorage.getItem('showInput1');
        const storedTrimenterior1Width = await AsyncStorage.getItem('archwidth');
        const storedTrimenterior1depth = await AsyncStorage.getItem('archwidth1');
        const storedTrimexterior2UnitCost = await AsyncStorage.getItem('archunitcost');
        const storedTrimexterior2UnitCost1 = await AsyncStorage.getItem('archunitcost1');
        const storedSelectedSprite = await AsyncStorage.getItem('selectedSprite');

        if (storedShowInput !== null) setShowInput(storedShowInput === 'true');
        if (storedShowInput1 !== null) setShowInput1(storedShowInput1 === 'true');
        if (storedTrimenterior1Width !== null) setArchwidth(parseFloat(storedTrimenterior1Width));
        if (storedTrimenterior1depth !== null) setArchwidth1(parseFloat(storedTrimenterior1depth));
        if (storedTrimexterior2UnitCost !== null) setArchunitcost(parseFloat(storedTrimexterior2UnitCost));
        if (storedTrimexterior2UnitCost1 !== null) setArchunitcost1(parseFloat(storedTrimexterior2UnitCost1));
        if (storedSelectedSprite !== null) setSelectedSprite(storedSelectedSprite);

        setSelectedGet(storedSelectedSprite || null);
      } catch (error) {
        console.error("Failed to load values from AsyncStorage", error);
      }
    };

    loadValues();
  }, [setSelectedGet]); 
  
  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === "Yes";
    
    if(newValue){
      setShowInput1 (false)

      setArchunit("false")
      setArchdoor1('true')
    }


    setShowInput(newValue);
    await AsyncStorage.setItem('showInput', newValue.toString());


  };

  const handleRadioChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === "Yes";

    if(newValue){
      setShowInput(false);
      setArchdoor1('false')
      setArchunit("true")

    }

    setShowInput1(newValue);
    await AsyncStorage.setItem('showInput1', newValue.toString());
  };

  const handleTrimenterior1WidthChange = async (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setArchwidth(value);
    await AsyncStorage.setItem('archwidth', value.toString());
  };
  
  const handleTrimenterior1depthChange = async (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setArchwidth1(value);
    await AsyncStorage.setItem('archwidth1', value.toString());
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setArchwidth(newValue);
      await AsyncStorage.setItem('archwidth', newValue.toString());
    }
  };

  const handleChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setArchwidth1(newValue);
      await AsyncStorage.setItem('archwidth1', newValue.toString());
    }
  };

  const handleTrimexterior2UnitCostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    await AsyncStorage.setItem('archunitcost', newValue.toString());
    setArchunitcost(newValue);
  };

  const handleTrimexterior2UnitCost1Change = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    await AsyncStorage.setItem('archunitcost1', newValue.toString());
    setArchunitcost1(newValue);
  };

  useEffect(() => {
    const storeData = async () => {
      try {
        await AsyncStorage.setItem('archunitcost', archunitcost.toString());
      } catch (error) {
        // Handle error
        console.error('Error saving data', error);
      }
    };

    storeData();
  }, [archunitcost]);
 
  useEffect(() => { 
    const storeData = async () => {
      try {
        await AsyncStorage.setItem('archunitcost1', archunitcost1.toString());
      } catch (error) {
        // Handle error
        console.error('Error saving data', error);
      }
    };

    storeData();
  }, [archunitcost1]);


  // const handleSpriteClick = async (gate: string,index:any) => {
  //   setSelectedSprite(gate);
  //   setSelectedGet(gate);
  //   setSelectedArchIndex(index);
  //   setSelectedArchDoorIndex(index);
  //   await AsyncStorage.setItem('selectedSprite', gate);
  // };


  const [archScrip, setArchScrip] = useState('')
  const [archDoor, setArchDoor] = useState('')


  // const ArchUnit = (src: any, index: any) => {
  //   setSelectedArchIndex(index); // Convert to string
  //   setArchScrip(src);
  // };


  const ArchNames = ["Eyebrow Top", "Square Top", "Round Top", "Elliptical Top"];
 
  const ArchUnit = async (src: any, index: any) => {
    setArchindex(index)
    setSelectedArchIndex(index);
    setArchScrip(src);

    // Get the corresponding name for the clicked image
    const imageName = ArchNames[index];




    await AsyncStorage.setItem('selectedArchImage', imageName); // Save the image name
  };


  const ArchDoor = async (src: any, index: any) => {
    setArchdoorindex(index)
    setSelectedArchDoorIndex(index); // Convert to string
    setArchDoor(src);



    const imageName = ArchNames[index];

    await AsyncStorage.setItem('selectedArchDoorImage', imageName); // Save the image name

  };

  

  //////////////////////   firebase start   ///////////////////

 

  useEffect(() => {

    const ArchNames = ["Eyebrow Top", "Square Top", "Round Top", "Elliptical Top"];


    setEditedConfig((prev) => ({ ...prev, selections: showInput })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, height: archwidth })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, unitCost: archunitcost1 })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, width: ArchNames[archindex] })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, estimatedCost: archunitcost1 })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, price: archunitcost1*2.5 })); // Update the editedConfig

    setEditedConfig2((prev) => ({ ...prev, selections: showInput1 })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, height: archwidth1 })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, unitCost: archunitcost })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, width:  ArchNames[archdoorindex] })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, estimatedCost:  archunitcost })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, price: archunitcost*2.5 })); // Update the editedConfig




  }, [showInput, archwidth, archunitcost1, showInput1, archwidth1, archunitcost,archindex,archdoorindex]);








  const [configIndex, setConfigIndex] = useState<number>(0);
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
  const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
  const navigate = useNavigate(); // For navigation after save





  const userId = savedata ;

  // Fetch the door config of the selected user from Firebase
  useEffect(() => {
    if (userId) {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);
        setEditedConfig(userData.doorConfig[3] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[4] || {}); // Initialize the 2nd door config
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  } else {
 
  }
  }, [userId]);

 



  const handleSave = () => {
    if (userId) {
    const userRef = ref(db, `users/${userId}/doorConfig`);

    // Create an updates object with string keys for doorConfig
    const updates: { [key: string]: DoorConfig } = {};
    updates["3"] = editedConfig;  // Use string keys for doorConfig
    updates["4"] = editedConfig2;

    // Log values to check what is being updated

    // Perform the update in Firebase
    update(userRef, updates)
      .then(() => {
        alert('Door configurations updated successfully!');
        // navigate('/'); // Navigate back to the main page
      })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
    } else {
    }
  };





  // setWidth(editedConfig.width) 





  useEffect(() => {
    if (userId) {

//     const ArchNames = ["Eyebrow Top", "Square Top", "Round Top", "Elliptical Top"];
 
//  const index = ArchNames.indexOf(editedConfig.width);

 


if (editedConfig) {

  // Only update state if not already set (i.e., null or 0)
  if (showInput === '') setShowInput(editedConfig.selections ==="yes"|| true ? true : false || false);
  if (archwidth === null || archwidth === doorWidth) setArchwidth(editedConfig.height || null);
  if (archunitcost1 === null || archunitcost1 === 0 || archunitcost1 === '') setArchunitcost1(editedConfig.unitCost || null);
  // if(index){ setSelectedArchIndex(index);}

}



    if (editedConfig2) {
        
      if (showInput1 === '') setShowInput1(editedConfig2.selections ==="yes"|| true ? true : false || false);
      if (archwidth1 === null || archwidth1 === doorWidth) setArchwidth1(editedConfig2.height || null);
      if (archunitcost === null || archunitcost === 0 || archunitcost === '') setArchunitcost(editedConfig2.unitCost || null);
      // if(index){  setSelectedArchDoorIndex(index);}
       
    }

    
  } else {
  }    
  }, [editedConfig, editedConfig2, showInput, archwidth, doorWidth, archunitcost1, archunitcost, archwidth1,]);


  

  
 

  /////////////////////   firebase end   ///////////////


  return (
    <Box>



      <Typography align="center" variant="h6">
        <b>Arch Config</b>
      </Typography>

      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
      >
        Type of Arch
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
          value={showInput ? "Yes" : "No"}
        >
          <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="No" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      {showInput && (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                marginTop: '200px'
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (

            <Grid container spacing={2}>
              {Arch.map((src, index) => (
                <Grid item xs={4} key={index} style={{ position: "relative" }}>
                  <img
                    src={src}
                    alt={`Image ${index + 1}`}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                    onClick={() => ArchUnit(src, index)} // Call ArchUnit on click
                  />
                  {archScrip === src && (
                    <div
                      style={{
                        position: "absolute",
                        top: "55%",
                        left: "58%",
                        transform: "translate(-50%, -50%)",
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                      }}
                    >
                      <CheckCircle style={{ color: KIT_COLOR, fontSize: "24px" }} />
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>

          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography>Rise of Arch</Typography>

            <input
              type="number"
              min="0"
              step="0.5"
              id="textInput"
              value={archwidth}
              onChange={handleChange}
              style={{
                textAlign: "center",
                width: "80px",
                marginRight: "8px",
                fontWeight: "bold",
                border: "1px solid #ccc",
                borderRadius: "4px",
                height: "35px",
                fontSize: "16px",
              }}
            />
            <Slider
              aria-label="Depth"
              value={archwidth}
              onChange={handleTrimenterior1WidthChange}
              getAriaValueText={(value: any) => `${value} units`}
              step={1}
              min={0}
              max={1110}
              sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
            />
          </Box>
          <br />
          <TextField
            label="Unit Cost"
            value={archunitcost1}
            onChange={handleTrimexterior2UnitCost1Change}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: "1" }}
            style={{ width: "100%" }}
            autoFocus // Makes sure it's focused when the component mounts
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
          />

        </>
      )}
      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
      >
        Arch Door
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleRadioChange1}
          value={showInput1 ? "Yes" : "No"}
        >
          <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="No" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </FormControl>
      <br />
      {showInput1 === true && (
        <>
          {isLoading1 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                marginTop: '200px'
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (


            <Grid container spacing={2}>
              {Arch2.map((src, index) => (
                <Grid item xs={4} key={index} style={{ position: "relative" }}>
                  <img
                    src={src}
                    alt={`Image ${index + 1}`}
                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                    onClick={() => ArchDoor(src, index)}
                  />

                  {archDoor === src && (
                    <div
                      style={{
                        position: "absolute",
                        top: "55%",
                        left: "58%",
                        transform: "translate(-50%, -50%)",
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                      }}
                    >
                      <CheckCircle style={{ color: KIT_COLOR, fontSize: "24px" }} />
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>

          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography>Rise of Arch</Typography>
            <input
              type="number"
              min="0"
              step="0.5"
              id="textInput"
              value={archwidth1}
              onChange={handleChange1}
              style={{
                textAlign: "center",
                width: "80px",
                marginRight: "8px",
                fontWeight: "bold",
                border: "1px solid #ccc",
                borderRadius: "4px",
                height: "35px",
                fontSize: "16px",
              }}
            />
            <Slider
              aria-label="Depth"
              value={archwidth1}
              onChange={handleTrimenterior1depthChange}
              getAriaValueText={(value: any) => `${value} units`}
              step={1}
              min={0}
              max={1110}
              sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
            />
          </Box>
          <br />
          <TextField
            label="Unit Cost"
            value={archunitcost}
            onChange={handleTrimexterior2UnitCostChange}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: "1" }}
            style={{ width: "100%" }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
            autoFocus // This will make the field focused as soon as the component is rendered
          />

        </>
      )}
      
      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />
    </Box>
  );
};

export default Archcom;





