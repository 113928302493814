import React, { useState, useEffect } from 'react';
import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate } from 'react-router-dom'; // To get userId from the URL
import { useColorContext } from "../ColorContext";

const EditDoorConfig = () => {
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig1, setEditedConfig1] = useState({}); // For the 0 index door config
  const [editedConfig2, setEditedConfig2] = useState({}); // For the 2 index door config
  const [width, setWidth] = useState(''); // Local state for width input
  const [height, setHeight] = useState(''); // Local state for height input
  const [unitCost, setUnitCost] = useState(''); // Local state for unitCost input
  const [sqftLft, setSqftLft] = useState(''); // Local state for sqftLft input
  const [category, setCategory] = useState(''); // Local state for category input
  const [selections, setSelections] = useState(''); // Local state for selections input
  const { savedata} = useColorContext() ;

  const userId = savedata; // Example user ID
  const navigate = useNavigate(); // For navigation after save

  // Fetch the door configs of the selected user from Firebase
  useEffect(() => {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);
        setEditedConfig1(userData.doorConfig[0] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[2] || {}); // Initialize the 2nd door config
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  }, [userId]);

  // Handle change in the input fields for both door configurations
  const handleInputChange = (e, configIndex) => {
    const { name, value } = e.target;
    if (configIndex === 0) {
      // Update local state for each field and also update the editedConfig1
      if (name === 'width') {
        setWidth(value);
      } else if (name === 'height') {
        setHeight(value);
      } else if (name === 'sqftLft') {
        setSqftLft(value);
      } else if (name === 'unitCost') {
        setUnitCost(value);
      } else if (name === 'category') {
        setCategory(value);
      } else if (name === 'selections') {
        setSelections(value);
      }

      // Update editedConfig1 with the changes
      setEditedConfig1((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else if (configIndex === 2) {
      setEditedConfig2((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  // Save the edited door config to Firebase
  const handleSave = () => {
    const userRef = ref(db, `users/${userId}/doorConfig`);

    // Update both the 0th and 2nd door configurations
    const updates = {};
    updates[0] = editedConfig1;
    updates[2] = editedConfig2;

    update(userRef, updates)
      // .then(() => {
      //   alert('Door configurations updated successfully!');
      //   navigate('/'); // Navigate back to the main page (UserTable)
      // })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
  };



  return (
    <div>
      <h2>Edit Door Configuration</h2>

      {/* Edit the first door configuration (index 0) */}
      {doorConfigs[0] && (
        <div>
          <h3>Door Config 0</h3>
          <div>
            <label>Category:</label>
            <input
              type="text"
              name="category"
              value={category || editedConfig1.category || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Selections:</label>
            <input
              type="text"
              name="selections"
              value={selections || editedConfig1.selections || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Width:</label>
            <input
              type="text"
              name="width"
              value={width || editedConfig1.width || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Height:</label>
            <input
              type="text"
              name="height"
              value={height || editedConfig1.height || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Sqft/Lft:</label>
            <input
              type="text"
              name="sqftLft"
              value={sqftLft || editedConfig1.sqftLft || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Unit Cost:</label>
            <input
              type="text"
              name="unitCost"
              value={unitCost || editedConfig1.unitCost || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Estimated Cost:</label>
            <input
              type="text"
              name="estimatedCost"
              value={editedConfig1.estimatedCost || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
          <div>
            <label>Price:</label>
            <input
              type="text"
              name="price"
              value={editedConfig1.price || ''}
              onChange={(e) => handleInputChange(e, 0)}
            />
          </div>
        </div>
      )}

      {/* Edit the second door configuration (index 2) */}
      {doorConfigs[2] && (
        <div>
          <h3>Door Config 2</h3>
          <div>
            <label>Category:</label>
            <input
              type="text"
              name="category"
              value={editedConfig2.category || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Selections:</label>
            <input
              type="text"
              name="selections"
              value={editedConfig2.selections || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Width:</label>
            <input
              type="text"
              name="width"
              value={editedConfig2.width || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Height:</label>
            <input
              type="text"
              name="height"
              value={editedConfig2.height || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Sqft/Lft:</label>
            <input
              type="text"
              name="sqftLft"
              value={editedConfig2.sqftLft || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Unit Cost:</label>
            <input
              type="text"
              name="unitCost"
              value={editedConfig2.unitCost || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Estimated Cost:</label>
            <input
              type="text"
              name="estimatedCost"
              value={editedConfig2.estimatedCost || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
          <div>
            <label>Price:</label>
            <input
              type="text"
              name="price"
              value={editedConfig2.price || ''}
              onChange={(e) => handleInputChange(e, 2)}
            />
          </div>
        </div>
      )}
      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />
    </div>
  );
};

export default EditDoorConfig;
