import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { CheckCircle } from "@mui/icons-material";
import { useColorContext } from "../ColorContext";
import "./Doorcss.css";
import "./Doubledoor.css";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Slider,
  TextField,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { Color } from "three";
import { log } from "console";



import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL
 

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}

interface DoorcomProps {
  onSelect: (value: any) => void; // Change 'any' to the appropriate type if known
}

const Doorcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {

  const KIT_COLOR = "#5F1C1D";
  const CustomRadio = styled(Radio)({
    color: KIT_COLOR,
    "&.Mui-checked": {
      color: KIT_COLOR,
    },
  });

  const [selectedSprite, setSelectedSprite] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedDoor , setTypeOfDoor, savedata, setSelectedArchIndex, selectedArchIndex } = useColorContext() as any;
  const [showInput, setShowInput] = useState<string | null>("SINGLE DOOR");
  const [width, setWidth] = useState<any>(() => {
    return Number(localStorage.getItem("width")) || '';
  });
  const [height, setHeight] = useState<any>(() => {
    return Number(localStorage.getItem("height")) || '';
  });
  const [unitCost, setUnitCost] = useState<any>(() => {
    return Number(localStorage.getItem("unitCost")) || 60;
  });
  const [unitCost1, setUnitCost1] = useState<any>(() => {
    return Number(localStorage.getItem("unitCost1")) || '';
  });

  const [TransformandSlidelight, setTransformandSlidelight] = useState<string>(() => {
    return String(localStorage.getItem("TransformandSlidelight")) || '';
  });
  const [doorSwing, setDoorSwing] = useState<string>(() => {
    return String(localStorage.getItem("doorSwing")) || '';
  });
  const [hardwareType, setHardwareType] = useState<string>(() => {
    return (localStorage.getItem("hardwareType")) || '';
  });

 
  // const [hardwareType, setHardwareType] = useState<string>("");
  // const [doorSwing, setDoorSwing] = useState<string>("");
  // const [TransformandSlidelight,setTransformandSlidelight] = useState<string>("");




  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem("width", width.toString());
  }, [width]);

  useEffect(() => {
    localStorage.setItem("height", height.toString());
  }, [height]);

  useEffect(() => {
    localStorage.setItem("unitCost", unitCost.toString());
  }, [unitCost]);

  useEffect(() => {
    localStorage.setItem("unitCost1", unitCost1.toString());
  }, [unitCost1]);

  useEffect(() => {    
    localStorage.setItem("TransformandSlidelight", TransformandSlidelight.toString());
  }, [TransformandSlidelight]);

  useEffect(() => {    
    localStorage.setItem("hardwareType", hardwareType.toString());
  }, [hardwareType]);

  useEffect(() => {
     
    localStorage.setItem("doorSwing", doorSwing.toString());
  }, [doorSwing]);
  
  const handleSpriteClick = (gate: string) => {
    setSelectedSprite(gate);
    setSelectedDoor(gate);
  };
  const handleDoorSwingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDoorSwing(event.target.value as string);
  };


  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //       const newValue = event.target.value;
  //       setTypeOfDoor(showInput);
  //   setShowInput(newValue);
  //   localStorage.setItem('myValue', newValue);  
   
  // };

  
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setShowInput(newValue); // Set the new value immediately
    setTypeOfDoor(newValue); // Update type of door
    localStorage.setItem('myValue', newValue);
};
 

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(Number(event.target.value));
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(Number(event.target.value));
  };

  const handleUnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost(Number(event.target.value));
  };
  const handleUnitCostChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost1(Number(event.target.value));
  };
  const handleWidthSliderChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setWidth(newValue as number);
  };

  const handleHeightSliderChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setHeight(newValue as number);
  };

  // const [doorsqrfeet,setDoorsqrfeet]=useState()

  const doorsqrfeet = (width * height) / 144;
  const Area=doorsqrfeet ;
  const UnitCost=unitCost ;
  const Estimatedcost=Area*UnitCost;
  
  const Price = Estimatedcost * 2.5
  const EstimatedCost1=Estimatedcost*0.2
   useEffect(() => {    
    localStorage.setItem("Estimatedcost", EstimatedCost1.toString());
  }, [EstimatedCost1]);









  const [hingeType, setHingeType] = useState<string>(() => {
    return localStorage.getItem("hingeType") || '';
  });
  
  // Update the hingeType and localStorage on change
  const handleHingeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setHingeType(newValue);
    localStorage.setItem("hingeType", newValue);
   };

  
   const [hingeCost, setHingeCost] = useState<number | string>('');



  
  // Update the hingeType and localStorage on change
  const handleConstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    localStorage.setItem("hingeCost", newValue);
 
    setHingeCost(newValue);
   };
 

   
   useEffect(()=>{
     
     const hingeunitcost = localStorage.getItem('hingeCost');
     const doortype =localStorage.getItem('myValue') 
     
     
     console.log("============<<<",hingeunitcost)
    

   if(hingeunitcost){
    setHingeCost(hingeunitcost)
   }
   if(doortype){
   setShowInput(doortype)
   }

  },[hingeCost])



    //////////////////////   firebase start   /////////


    

        
    useEffect(() => {

      
      localStorage.setItem("Estimatedcost", EstimatedCost1.toString());
      if (userId) {
      setEditedConfig((prev) => ({ ...prev, width: doorSwing })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, height: hingeType })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, unitCost: hingeCost })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, selections: showInput })); // Update the editedConfig
  
    } else {
    }
  
     }, [doorSwing,hingeType,showInput,hingeCost]);



      const [configIndex, setConfigIndex] = useState<number>(0);
      const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
      const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
      const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
      const [editedConfig3, setEditedConfig3] = useState<DoorConfig>({});
      const [editedConfig4, setEditedConfig4] = useState<DoorConfig>({});
      const navigate = useNavigate(); // For navigation after save


  
    let userId = savedata;

    
  
    // Fetch the door config of the selected user from Firebase
    useEffect(() => {
      // Check if userId is valid (not null or undefined)

      
      if (userId) {
        const userRef = ref(db, `users/${userId}`);
        get(userRef).then((snapshot) => {
          const userData = snapshot.val();
          if (userData && userData.doorConfig) {
            setDoorConfigs(userData.doorConfig);
            setEditedConfig(userData.doorConfig[1] || {}); // Initialize the 0th door config
            setEditedConfig2(userData.doorConfig[2] || {}); // Initialize the 2nd door config
            setEditedConfig3(userData.doorConfig[3] || {}); // Initialize the 2nd door config
            setEditedConfig4(userData.doorConfig[4] || {}); // Initialize the 2nd door config
          }
        }).catch((error) => {
          alert('Failed to load door configuration');
        });
      } else {
      }
    }, [userId]);
    
    
    
  
 
  
    
    
    const handleSave = () => {

      if (userId) {
      const userRef = ref(db, `users/${userId}/doorConfig`);
    
      // Create an updates object with string keys for doorConfig
      const updates: { [key: string]: DoorConfig } = {};
      updates["1"] = editedConfig;  // Use string keys for doorConfig
      updates["2"] = editedConfig2;
      updates["3"] = editedConfig3;
      updates["4"] = editedConfig4;
    
      // Log values to check what is being updated
    
      // Perform the update in Firebase
      update(userRef, updates)
        .then(() => {
          alert('Door configurations updated successfully!');
          // navigate('/'); // Navigate back to the main page
        })
        .catch((error) => {
          alert('Failed to update door configuration');
        });
      } else {
      }
    };
    
  
    
  
  
     // setWidth(editedConfig.width) 
  




 useEffect(() => {
  if (userId) {


    const ArchNames = ["Eyebrow Top", "Square Top", "Round Top", "Elliptical Top"];
 
    const index = ArchNames.indexOf(editedConfig3.width);
    const index2 = ArchNames.indexOf(editedConfig4.width);
   


    if (editedConfig) {
      // Only update state if not already set (i.e., null or 0)
      if ( doorSwing === '' || doorSwing === 'null' || doorSwing === "doorSwing") setDoorSwing(editedConfig.width || '');
      if ( hingeType === '' || hingeType === 'null' || hingeType === "hingeType") setHingeType(editedConfig.height || '');
      if ( hingeCost === '' || hingeCost === 'null' || hingeCost === null || hingeCost === 0 ) setHingeCost(editedConfig.unitCost || '');
      if ( showInput === '' || showInput === 'SINGLE DOOR' || showInput === 'null' ) setShowInput(editedConfig.selections ||'');
 
    }


    if(editedConfig3){

      if(index){ setSelectedArchIndex(index);}

    }

    if(editedConfig4){

      if(index2){ setSelectedArchIndex(index2);}

    }
    
  } else {
  }
  
}, [editedConfig, editedConfig2, doorSwing, hingeCost,showInput,]);


    console.log(".............123",editedConfig3.width)
    console.log(".............456",editedConfig4.width)
    
    /////////////////////   firebase end   /////////////


  
  return (
    <Box>

<Box>




<Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white',marginTop: '20px' }}>
        Door Swing From Ext View
      </Typography>
      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
        <Select
          value={doorSwing}
          onChange={(e) => setDoorSwing(e.target.value)}
          // onChange={handleDoorSwingChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><em>Select Swing Direction</em></MenuItem>
          <MenuItem value="IN RIGHT">In Right</MenuItem>
          <MenuItem value="IN LEFT">In Left</MenuItem>
          <MenuItem value="OUR RIGHT">Our Right</MenuItem>
          <MenuItem value="OUR LEFT">Our Left</MenuItem>
          <MenuItem value="SLIDE">Slide</MenuItem>
          <MenuItem value="FOLD">Fold</MenuItem>
          <MenuItem value="TBD">TBD</MenuItem>
        </Select>
      </FormControl>

  

<Box>
 
      <Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white', marginTop: '20px' }}>
    Hinge
  </Typography>
  <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
    <Select
      value={hingeType}
      onChange={handleHingeChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value=""><em>Select Swing Direction</em></MenuItem>
      <MenuItem value={showInput === 'SINGLE DOOR' ? 'Right' : showInput === 'DOUBLE DOOR' ? 'Left Active' : showInput === 'FOLDING DOOR' ? "Right to Left" : 'Active'}>
        {showInput === 'SINGLE DOOR' ? 'Right' : showInput === 'DOUBLE DOOR' ? 'Left Active' : showInput === 'FOLDING DOOR' ? "Right to Left" : 'Active'}
      </MenuItem>
      <MenuItem value={showInput === 'SINGLE DOOR' ? 'Left' : showInput === 'DOUBLE DOOR' ? 'Right Active' : showInput === 'FOLDING DOOR' ? "Left to Right" : 'InActive'}>
        {showInput === 'SINGLE DOOR' ? 'Left' : showInput === 'DOUBLE DOOR' ? 'Right Active' : showInput === 'FOLDING DOOR' ? "Left to Right" : 'InActive'}
      </MenuItem>
    </Select>
  </FormControl>

<br/> 
{ hingeType ? 

<TextField
  value={hingeCost}
  onChange={handleConstChange}
  label="Unit Cost"
  variant="outlined"
  type="number"
  InputProps={{
    inputProps: { 
      min: 0, 
      // step: "0.5"  // Ensures the increment/decrement step is 0.5
    }
  }}
  style={{ width: "100%" }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: KIT_COLOR, // Default border color
      },
      '&:hover fieldset': {
        borderColor: KIT_COLOR, // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: KIT_COLOR, // Border color when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: KIT_COLOR, // Label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: KIT_COLOR, // Label color when focused
    },
  }}
/>

:null }
</Box>





<Typography
      
      variant="h6"
      align="center"
      gutterBottom
      sx={{ backgroundColor: "#5F1C1D",color:'white', marginTop: '20px'}}
    >
      Door
    </Typography>
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleRadioChange}
        value={showInput}
      >
        <FormControlLabel
          value="SINGLE DOOR"
          control={<CustomRadio />}
          label="Single Door"
        />
        <FormControlLabel
          value="DOUBLE DOOR"
          control={<CustomRadio />}
          label="Double Door"
        />

<FormControlLabel
          value="FOLDING DOOR"
          control={<CustomRadio />}
          label="Folding Door"
        />

<FormControlLabel
          value="SLIDING DOOR"
          control={<CustomRadio />}
          label="Sliding Door"
        />




      </RadioGroup>
    </FormControl>





{showInput === "SINGLE DOOR" ? (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "with-sidelight-and-transom",
                "with-sidelights",
                "with-simulated-divided-light",
                "with-transom",
                "with-true-divide-lights",
                "with-operable-glass",
              ].map((type, index) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CheckCircle
                          style={{ color: KIT_COLOR, fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
          <br />
        </>
      ) : 
      showInput === "DOUBLE DOOR" ? (                                  
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                marginTop: "200px",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={2} className="sprite-container">
              {[
                "double-door-true-divide-lights",
                "double-door-sidelights1",
                "double-door-sidelights",
                "double-door-transom",
                "double-door-sidelight-and-transom",
                "double-door-simulated-divided-light",
              ].map((type, index) => (
                <Grid item xs={6} sm={6} md={6} key={type}>
                  <span
                    className={`css-sprite-${type}`}
                    onClick={() => handleSpriteClick(type)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      display: "block",
                    }}
                  >
                    {selectedSprite === type && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CheckCircle
                          style={{ color: KIT_COLOR, fontSize: "24px" }}
                        />
                      </div>
                    )}
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )
    :null
    }

</Box>

<br />
      <br/>
      {savedata ?
      
      // <div style={{display:'flex',justifyContent:"center",}}>

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>
// </div>
:null}
<br />
<br />

    </Box>
  );
};

export default Doorcom;
