import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage"; // For React Native

import Thicknessofdoor from "./Thicknessofdoor";
import Jambdepth from "./Jambdepth";
import Jambwidth from "./Jambwidth";

import { useColorContext } from "../ColorContext";

import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}


const widthOptions = ["0", '1', '2', "1 1/2"];
const depthOptions = ['0', '4', "4 1/2", '5', "5 1/6", "6 1/2", '7', "7 1/2", '8', '9', '10' ,"CUSTOM"];

const Jambcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  const [jumbwidth, setJumbwidth] = useState<number | string>(widthOptions[0]);
  const [jumbdepth, setJumbdepth] = useState<number | string>(depthOptions[0]);
  const [customDepth, setCustomDepth] = useState<string>("");
  const preOptions = [
    '',
    "1x2",
   "1 1/2x2",
   "2x2",
   "3x2",
   "4x2",
   "TBD",
   "CUSTOM"
  ];

  const [showWidthCost, setShowWidthCost] = useState(false);
  const [showDepthCost, setShowDepthCost] = useState(false);
  const [jumbwidthCost, setJumbwidthCost] = useState<number>(0);
  const [jumbdepthCost, setJumbdepthCost] = useState<number>(8);
  const [custom, setCustom] = React.useState<string>("");
  const [hardwarepre, setHardwarepre] = useState<number | string>(0);
  const { savedata} = useColorContext() as any;

  const KIT_COLOR = "#5F1C1D";

  const handleWidthChange = async (
    event: SelectChangeEvent<string | number>
  ) => {
    const newWidth = event.target.value as number | string;
    setJumbwidth(newWidth);
    setShowWidthCost(true);
    await AsyncStorage.setItem("jumbwidth", newWidth.toString());
  };

 
  const handleDepthChange = async (
    event: SelectChangeEvent<string | number>
  ) => {
    const newDepth = event.target.value as number | string;

    
    // Check if the selected value is "custom"
    if (newDepth === "custom") {
      setShowDepthCost(true);
      setJumbdepth(newDepth); // Set jumbdepth to "custom" to show custom input
    } else {
      setJumbdepth(newDepth);
      setCustomDepth("")
      setShowDepthCost(true); // Keep showing cost input for predefined values
      await AsyncStorage.setItem("jumbdepth", newDepth.toString());
    }
  };
  
  
  // const handleDepthChange = async (
  //   event: SelectChangeEvent<string | number>
  // ) => {
  //   const newDepth = event.target.value as number | string;
  //   setJumbdepth(newDepth);
  //   if (newDepth === "custom") {
  //     setShowDepthCost(true); 
  //   } else {
  //     setShowDepthCost(true);
  //     await AsyncStorage.setItem("jumbdepth", newDepth.toString());
  //   }
  // };

  const handleDepthChanges = async (
    event: SelectChangeEvent<string | number>
  ) => {
    const newDepth = event.target.value as number | string;


    // Check if the selected value is "custom"
    if (newDepth === "custom") {
      // setShowDepthCost(true);
      setHardwarepre(newDepth); // Set hardwarepre to "custom" to show custom input
    } else {
      setHardwarepre(newDepth);
      setCustom("");
      // setShowDepthCost(true); // Keep showing cost input for predefined values
      await AsyncStorage.setItem("hardwarepre12", newDepth.toString());
    }
  };

  const handleWidthCostChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setJumbwidthCost(value);
    await AsyncStorage.setItem("jumbwidthCost", value.toString());
  };


  // useEffect(() => {
  //   const storeData = async () => {
  //     try {
  //       await AsyncStorage.setItem('jumbwidthCost', jumbwidthCost.toString());
  //     } catch (error) {
  //       // Handle error
  //     }
  //   };

  //   storeData();
  // }, [jumbwidthCost]); 


  const handleDepthCostChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value) || 0;
    setJumbdepthCost(value);
    await AsyncStorage.setItem("jumbdepthCost", value.toString());
  };

  useEffect(() => {
    const loadValues = async () => {
      const storedWidth = await AsyncStorage.getItem("jumbwidth");
      const storedDepth = await AsyncStorage.getItem("jumbdepth");
      const storedWidthCost = await AsyncStorage.getItem("jumbwidthCost");
      const storedDepthCost = await AsyncStorage.getItem("jumbdepthCost");
      const storedHardwarepre = await AsyncStorage.getItem("hardwarepre12");



      if (storedWidth) setJumbwidth(storedWidth);
      if (storedDepth) setJumbdepth(storedDepth);
      if (storedWidthCost) setJumbwidthCost(parseFloat(storedWidthCost));
      if (storedDepthCost) setJumbdepthCost(parseFloat(storedDepthCost));
      if (storedHardwarepre) setHardwarepre( storedHardwarepre);

      setShowWidthCost(storedWidth !== null);
      setShowDepthCost(storedDepth !== null);
    };

    loadValues();
  }, []);



  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  // Local storage se value get karna
  useEffect(() => {
    const storedValue = localStorage.getItem('StileWidthThickness');
    const storedValue2 = localStorage.getItem('StileWidthThickness2');
    if (storedValue) {
      setInputValue(storedValue);
    }
    if (storedValue2) {
      setInputValue2(storedValue2);
    }


  }, []);

  // Local storage me value set karna jab inputValue change ho
  useEffect(() => {
    localStorage.setItem('StileWidthThickness', inputValue);
    localStorage.setItem('StileWidthThickness2', inputValue2);
  }, [inputValue,inputValue2]);



  

  //////////////////////   firebase start   /////////






  useEffect(() => {

 

    setEditedConfig((prev) => ({ ...prev, width: jumbwidth })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, unitCost: jumbwidthCost })); // Update the editedConfig


    setEditedConfig2((prev) => ({ ...prev, width: jumbdepth })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, unitCost: jumbdepthCost })); // Update the editedConfig
  
    setEditedConfig3((prev) => ({ ...prev, selections: hardwarepre })); // Update the editedConfig

  
  }, [jumbwidth,jumbwidthCost,jumbdepth, jumbdepthCost , hardwarepre ]);








  const [configIndex, setConfigIndex] = useState<number>(0);
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
  const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
  const [editedConfig3, setEditedConfig3] = useState<DoorConfig>({});
  const navigate = useNavigate(); // For navigation after save





  const userId = savedata ;

  // Fetch the door config of the selected user from Firebase
  useEffect(() => {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);
        setEditedConfig(userData.doorConfig[5] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[6] || {}); // Initialize the 2nd door config
        setEditedConfig3(userData.doorConfig[7] || {}); // Initialize the 2nd door config
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  }, [userId]);

 



  const handleSave = () => {
    const userRef = ref(db, `users/${userId}/doorConfig`);

    // Create an updates object with string keys for doorConfig
    const updates: { [key: string]: DoorConfig } = {};
    updates["5"] = editedConfig;  // Use string keys for doorConfig
    updates["6"] = editedConfig2;
    updates["7"] = editedConfig3;

    // Log values to check what is being updated

    // Perform the update in Firebase
    update(userRef, updates)
      .then(() => {
        alert('Door configurations updated successfully!');
        // navigate('/'); // Navigate back to the main page
      })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
  };





  // setWidth(editedConfig.width) 





  useEffect(() => {

    
    if (editedConfig) {

       if(jumbwidth === (widthOptions[0] ) ) { setJumbwidth(editedConfig.width  || '0' );}
       if(jumbwidthCost === 0 ) { setJumbwidthCost(editedConfig.unitCost || 0 );}
    
     }

    if (editedConfig2) {

      if (jumbdepth === depthOptions[0]) setJumbdepth(editedConfig2.width || '0' );
      if (jumbdepthCost === 0 ) setJumbdepthCost(editedConfig2.width || 0 );
   
    }


    if (editedConfig3) {

      if (hardwarepre === 0 ) { setHardwarepre(editedConfig3.selections || 0 );  }

    }
    
    
  }, [editedConfig, editedConfig2,jumbwidth, jumbdepth,jumbdepthCost,hardwarepre  ]);
  
 

  /////////////////////   firebase end   ///////////////




  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Jamb
      </Typography>
      <Box mb={3}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Jamb Width
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Select
            value={jumbwidth}
            onChange={handleWidthChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Width" }}
            fullWidth
          >
            <MenuItem disabled value="">
              JAMB WIDTH (FRONT VIEW SELECT)
            </MenuItem>
            {widthOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {jumbwidth > "0"
          ? (
            <TextField
              label="Unit Cost"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={jumbwidthCost}
              onChange={handleWidthCostChange}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: KIT_COLOR, // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: KIT_COLOR, // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: KIT_COLOR, // Border color when focused
                  },
                },
                "& .MuiInputLabel-root": {
                  color: KIT_COLOR, // Label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: KIT_COLOR, // Label color when focused
                },
                mt: 2,
              }}
            />
  ):null}
        </Box>
      </Box>

      <Box mb={3}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Jamb depth
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          
          <Select
            value={jumbdepth}
            onChange={handleDepthChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Depth" }}
            fullWidth
          >
            <MenuItem disabled value="">
              JAMB DEPTH (INT TO EXT SELECT)
            </MenuItem>
            {depthOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
            {/* <MenuItem value="custom">Add Custom depth</MenuItem> */}
          </Select> 
          {(jumbdepth === "Add Custom" ) && (

<TextField
 type="number"
value={inputValue2}
onChange={(e) => setInputValue2(e.target.value)}
InputProps={{
  inputProps: { min: 0 },
}}
fullWidth
sx={{
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: KIT_COLOR, // Default border color
    },
    "&:hover fieldset": {
      borderColor: KIT_COLOR, // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: KIT_COLOR, // Border color when focused
    },
  },
  "& .MuiInputLabel-root": {
    color: KIT_COLOR, // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: KIT_COLOR, // Label color when focused
  },
  mt: 2,
}}
/>

          )}
          { jumbdepth > '0' 
          
          ?
          
          (
            <TextField
              label="Unit Cost"
              type="number"
              value={jumbdepthCost}
              onChange={handleDepthCostChange}
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: KIT_COLOR, // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: KIT_COLOR, // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: KIT_COLOR, // Border color when focused
                  },
                },
                "& .MuiInputLabel-root": {
                  color: KIT_COLOR, // Label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: KIT_COLOR, // Label color when focused
                },
                mt: 2,
              }}
            />
          ):null
          }
        </Box>
      </Box>
      <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Stile Width Thickness of door 
        </Typography>
      <Box mb={3}>
      {/* Stile Width Thickness of door */}

      <Select
          value={hardwarepre}
          onChange={handleDepthChanges}
          displayEmpty
          inputProps={{ "aria-label": "Select Prep Type" }}
          fullWidth
        >
          <MenuItem disabled value="">
          Stile Width Thickness of door 
          </MenuItem>
          {preOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          {/* <MenuItem value="custom">Add Custom </MenuItem> */}
        </Select>
        <br />
        {(hardwarepre === "Add Custom" ) && (

<TextField
 type="number"
value={inputValue}
onChange={(e) => setInputValue(e.target.value)}
InputProps={{
  inputProps: { min: 0 },
}}
fullWidth
sx={{
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: KIT_COLOR, // Default border color
    },
    "&:hover fieldset": {
      borderColor: KIT_COLOR, // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: KIT_COLOR, // Border color when focused
    },
  },
  "& .MuiInputLabel-root": {
    color: KIT_COLOR, // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: KIT_COLOR, // Label color when focused
  },
  mt: 2,
}}
/>
 
        )}
      </Box>

      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />
   
    </Box>
  );
};

export default Jambcom;