import  React,{useState,useEffect} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useColorContext } from "../ColorContext";


import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}



const Thresholdcom = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {
  const [thresholdType, setThresholdType] = React.useState<string>(
    localStorage.getItem("thresholdType") || ""
  );
  const [thresholdHT, setThresholdHT] = React.useState<string>(localStorage.getItem("thresholdHT") || "");
  const [customThresholdHT, setCustomThresholdHT] = React.useState<string>("");
  const [showCustomInput, setShowCustomInput] = React.useState<boolean>(false);
  const [thresholdunitCost, setThresholdunitCost] = React.useState<number>(
    parseFloat(localStorage.getItem("thresholdunitCost") || "170")
  );
  const [thresholdunitCost2, setUnitCost1] = React.useState<number>(
    parseFloat(localStorage.getItem("thresholdunitCost2") || "100")
  );
  const [thresholdwidth, setThresholdwidth] = React.useState<number | "">(
    parseFloat(localStorage.getItem("thresholdwidth") || "20")
  );
  const KIT_COLOR = "#5F1C1D";

  const handleThresholdTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setThresholdType(newValue);
    localStorage.setItem("thresholdType", newValue);
  };

  const handleThresholdHTChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setThresholdHT(newValue);
    setShowCustomInput(newValue === "CUSTOM");
    if (newValue !== "CUSTOM") {
      setCustomThresholdHT(""); // Clear custom input if not selected
    }
    localStorage.setItem("thresholdHT", newValue);
  };

  const handleCustomThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCustomThresholdHT(newValue);
    setThresholdHT(newValue); // Update thresholdHT with custom input
    localStorage.setItem("thresholdHT", newValue);
  };

  const handleUnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setThresholdunitCost(newValue);
    localStorage.setItem("thresholdunitCost", newValue.toString());
  };

  const handleUnitCostChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || 0;
    setUnitCost1(newValue);
    localStorage.setItem("thresholdunitCost2", newValue.toString());
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value) || "";
    setThresholdwidth(newValue);
    localStorage.setItem("thresholdwidth", newValue.toString());
  };



  const { savedata} = useColorContext() as any;



    

  //////////////////////   firebase start   /////////






  useEffect(() => {
  

    setEditedConfig((prev) => ({ ...prev, selections: thresholdType })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, unitCost: thresholdunitCost })); // Update the editedConfig


    setEditedConfig2((prev) => ({ ...prev, selection: thresholdHT })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, width: thresholdwidth })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, unitCost: thresholdunitCost2 })); // Update the editedConfig

  
  }, [  thresholdType, thresholdunitCost , thresholdHT,  thresholdwidth, thresholdunitCost2
     
    
    ]);








  const [configIndex, setConfigIndex] = useState<number>(0);
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
  const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
 
  const navigate = useNavigate(); // For navigation after save





  const userId = savedata;

  // Fetch the door config of the selected user from Firebase
  useEffect(() => {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);

        setEditedConfig(userData.doorConfig[15] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[16] || {}); // Initialize the 2nd door config
 
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  }, [userId]);

 



  const handleSave = () => {
    const userRef = ref(db, `users/${userId}/doorConfig`);

    // Create an updates object with string keys for doorConfig
    const updates: { [key: string]: DoorConfig } = {};
    updates["15"] = editedConfig;  // Use string keys for doorConfig
    updates["16"] = editedConfig2;
 


    // Perform the update in Firebase
    update(userRef, updates)
      .then(() => {
        alert('Door configurations updated successfully!');
        // navigate('/'); // Navigate back to the main page
      })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
  };





  // setWidth(editedConfig.width) 





  useEffect(() => {

    
    if (editedConfig) {

        if(thresholdType === null || thresholdType === '' || thresholdType === "thresholdType"  ) { setThresholdType(editedConfig.selections || '' );} 
       if( thresholdunitCost === 170   ) { setThresholdunitCost(editedConfig.unitCost || 170 );}
    
     }

    if (editedConfig2) {

      if(thresholdHT === 'null' || thresholdHT === 'thresholdHT' || thresholdHT === ''   ) { setThresholdHT(editedConfig2.selections || '' );}
      if(thresholdwidth === 20  ) { setThresholdwidth(editedConfig2.width || 20 );}
       if( thresholdunitCost2 === 100 ) { setUnitCost1(editedConfig2.unitCost ||100 );}   

    }

 
    
    
    
  }, [editedConfig, editedConfig2, thresholdHT , thresholdunitCost2
   ]);
  

  /////////////////////   firebase end   ///////////////












  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Threshold
      </Typography>

      {/* Threshold Type Dropdown */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Threshold Type
        </Typography>
        <Select
          value={thresholdType}
          onChange={handleThresholdTypeChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select Threshold Type</MenuItem>
          <MenuItem value="STEP">STEP</MenuItem>
          <MenuItem value="EXTENDED STEP"> EXTENDED STEP </MenuItem>
          <MenuItem value="OUT SWING"> OUT SWING </MenuItem>
          <MenuItem value="ADA TOP OF FLOOR">ADA TOP OF FLOOR</MenuItem>
          <MenuItem value="ADA-BUTT FLOOR">ADDA - BUTT FLOOR</MenuItem>
        </Select>
      </Box>
      {/* Show Unit Cost for Threshold Type Selection */}
      {thresholdType && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Unit Cost</Typography>
          <TextField
            value={thresholdunitCost}
            onChange={handleUnitCostChange}
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            type="number"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: KIT_COLOR },
                "&:hover fieldset": { borderColor: KIT_COLOR },
                "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
              },
              "& .MuiInputLabel-root": { color: KIT_COLOR },
              "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
            }}
          />
        </Box>
      )}
      {/* Threshold HT Dropdown */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#5F1C1D",
            color: "white",
            pl: 2,
            pt: 0.5,
            pb: 0.5,
            mt: 2,
            mb: 2,
          }}
        >
          Threshold HT
        </Typography>
        <Select
          value={thresholdHT}
          onChange={handleThresholdHTChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select Threshold HT</MenuItem>
          <MenuItem value="3/4">3/4</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="1 1/2">1 1/2</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="2 1/2">2 1/2</MenuItem>
          <MenuItem value="CUSTOM">Custom</MenuItem>
        </Select>
      </Box>

      {/* Custom Threshold HT Input */}
      {showCustomInput && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Custom Threshold HT</Typography>
          <TextField
            value={customThresholdHT}
            onChange={handleCustomThresholdChange}
            fullWidth
            placeholder="Enter custom value"
            type="text"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: KIT_COLOR },
                "&:hover fieldset": { borderColor: KIT_COLOR },
                "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
              },
              "& .MuiInputLabel-root": { color: KIT_COLOR },
              "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
            }}
          />
        </Box>
      )}



      {/* Show thresholdwidth and Unit Cost fields */}
      {thresholdHT !== "" && (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Threshold Width</Typography>
            <TextField
              value={thresholdwidth}
              onChange={handleWidthChange}
              fullWidth
              // InputProps={{ inputProps: { min: 0 } }}

              InputProps={{
                inputProps: { 
                  min: 0, 
                  step: "0.5"  // Ensures the increment/decrement step is 0.5
                }
              }}
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: KIT_COLOR },
                  "&:hover fieldset": { borderColor: KIT_COLOR },
                  "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
                },
                "& .MuiInputLabel-root": { color: KIT_COLOR },
                "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Unit Cost</Typography>
            <TextField
              value={thresholdunitCost2}
              onChange={handleUnitCostChange1}
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: KIT_COLOR },
                  "&:hover fieldset": { borderColor: KIT_COLOR },
                  "&.Mui-focused fieldset": { borderColor: KIT_COLOR },
                },
                "& .MuiInputLabel-root": { color: KIT_COLOR },
                "& .MuiInputLabel-root.Mui-focused": { color: KIT_COLOR },
              }}
            />
          </Box>
        </>
      )}

<br/>
<br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />


    </Box>
  );
};

export default Thresholdcom;
