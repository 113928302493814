import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BalconyIcon from "@mui/icons-material/Balcony";
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import LockIcon from '@mui/icons-material/Lock';
import glassimage from '../assets/glass.png';
import pullhandleimage from '../assets/pullhandle.png';

import handleimage from '../assets/door-handle-fotor-2024101711152.png';
import jambimage from '../assets/jamb.png';
import archimage from '../assets/arch.png';
import installtion from '../assets/installtion.png';
import door from '../assets/door.png';
import enquiry from '../assets/enquiry.png';
import single from '../assets/single.png';





import {
  Button,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import Enquiryexport from "./Enquiry/Enquiryexport";

import Istallations from "./Installation/Installations";
import Micscom from "./Mics/Micscom";
import Kitsscom from "./Kits/Kitscom";
import Thresholdcom from "./Threshold/Thresholdcom";
import Fittingcom from "./Fitting/Fittingcom";
import Colorcom from "./Colors/Colorcom";
import Glasscom from "./Glass/Glasscom";
import Jambcom from "./Jamb/Jambcom";
import Archcom from "./Arch/Archcom";
import Doorcom from "./Door/Doorcom";
import Paintcolor from "./Colors/Paintcolor";
import Archdoor from "./Arch/Archdoor";
import Doorstyle from "./Door/Doorstyle";
import GlassTemp from "./Glass/GlassTemp";
import Handlecom from "./Handle&grill/handlecom";
import DoorType from "./Doors/DoorType";
import MyComponent from "./Door/GLTFviewer";
// import DoorType from "./Doors/doorType";
// import DoorType from "./Doors/doorType";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth * 2,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth * 2}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth * 2,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "hidden", // Disable scroll for the Drawer itself
  height: "calc(100vh - 64px)", // Set a fixed height for the Drawer (adjust according to toolbar or header height)
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "hidden", // Disable scroll for the Drawer itself
      height: "100%", // Ensure drawer height is fixed
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "hidden",
      height: "100%",
    },
  }),
}));

export default function MiniDrawer() {
  const [activeCategory, setActiveCategory] = React.useState<string | null>(
    null
  );

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(
    null
  );

  const [selectedRightComponent, setSelectedRightComponent] =
    React.useState<React.ReactNode | null>(null);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setSelectedRightComponent(null);
    setActiveCategory(category); // Set active category
  };

  const handleComponentSelect = (component: React.ReactNode) => {
    setSelectedRightComponent(component);
  };

  const renderMiddlePanelContent = () => {
    if (!selectedCategory) {
      // return <Typography>Select a category from the sidebar</Typography>;
      return <Doorcom onSelect={handleComponentSelect} />
    }

    switch (selectedCategory) {
      case "Door":
        return <Doorcom onSelect={handleComponentSelect} />;

        // case "DoorType":
        //   return <DoorType onSelect={handleComponentSelect} />;
          case "DoorType":
            return <DoorType onSelect={handleComponentSelect} />;
      case "Arch":
        return <Archcom onSelect={handleComponentSelect} />;
      case "Handle/Grill":
        return <Handlecom />;
      case "Jamb":
        return <Jambcom onSelect={handleComponentSelect} />;
      case "Glass":
        return <Glasscom onSelect={handleComponentSelect} />;
      case "Colors":
        return <Colorcom onSelect={handleComponentSelect} />;
      case "Fitting":
        return <Fittingcom onSelect={handleComponentSelect} />;
      case "Threshold":
        return <Thresholdcom onSelect={handleComponentSelect} />;
      case "Kits":
        return <Kitsscom onSelect={handleComponentSelect} />;
      case "Misc":
        return <Micscom onSelect={handleComponentSelect} />;
      case "Installation":
        return <Istallations onSelect={handleComponentSelect} />;
      case "Enquiry/Export":
        return <Enquiryexport />;
      default:
        return <Typography>No middle component available</Typography>;
      // return <Doorcom onSelect={handleComponentSelect} />;
    }
  };

  const renderRightPanelContent = () => {
    // if (!selectedRightComponent) {
    //   return (
    //     <Typography sx={{ pt: 2 }}>
    //       Select an item from the middle section
    //     </Typography>
    //   );
    // }

    return <Box sx={{ p: 0 }}>{selectedRightComponent}</Box>;
  };

  interface CustomListItemTextProps {
    topText: string;
    bottomText: string;
  }

  const CustomListItemText: React.FC<CustomListItemTextProps> = ({
    topText,
    bottomText,
  }) => (
    <div
      style={{
        margin: 0,
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.5",
        letterSpacing: "0.00938em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span>{topText}</span>
      <span>{bottomText}</span>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#5F1C1D" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ height: 80, width: 80, pt: "0.8rem" }}>
            <CardMedia
              component="img"
              image="osl-logo-r2.png"
              alt="Placeholder Image"
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography
            style={{ padding:'32px 1px', color: "white", fontSize: "bold" ,backgroundColor:'#5f1c1d', marginLeft:"-20px", marginRight:"-55px"}}
            variant="h5"
            sx={{
              flex: 1,
              textAlign: "center",
              visibility: open ? "visible" : "hidden",
            }}
          >
            <b>Door Specification</b>
          </Typography>
          <IconButton onClick={handleDrawerClose} sx={{ color: 'White',fontSize: 30 }} >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Box
          component="main"
          sx={{
            display: "flex",
            paddingTop:"25px"
          }}
        >
          
      <List
            sx={{
              width: 390,
              overflowY: "auto",
              maxHeight: "calc(100vh - 64px)",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "5px",
              },
            }}
          >

              <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                   marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Door" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Door" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Door" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Door" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Door")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={door} alt="Jamb" style={{width:"30px",height:"30px"}}  />

                </ListItemIcon>
                <ListItemText
                  primary="Door"
                  sx={{
                    minWidth: 0,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                />
              </ListItemButton>
            </ListItem>



            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "DoorType" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "DoorType" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "DoorType" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "DoorType" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("DoorType")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={single} alt="single" style={{width:"30px",height:"30px"}}  />

                </ListItemIcon>
                <ListItemText
                  primary="DoorType"
                  sx={{
                    minWidth: 0,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                />
              </ListItemButton>
            </ListItem>




            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Arch" ? "#fff" : "#1a1919",  
                  backgroundColor:
                    activeCategory === "Arch" ? "#89292b" : "transparent",  
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Arch" ? "#d06264" : "#9d2f31",  
                    color: activeCategory !== "Arch" ? "#ffffff" : "#fff",  
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Arch")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  
                  {/* <DoorSlidingIcon /> */}
                  <img src={archimage} style={{ width: "30px", height: "30px" }}/>
                </ListItemIcon>
                <ListItemText
                  primary="Arch"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Handle/Grill" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Handle/Grill"
                      ? "#89292b"
                      : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Handle/Grill" ? "#d06264" : "#9d2f31", // Different hover color
                    color:
                      activeCategory !== "Handle/Grill" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Handle/Grill")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={pullhandleimage} alt="Jamb" style={{width:"25px",height:"25px"}}  />

                </ListItemIcon>
                <ListItemText
                  primary="Handle/grill"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Jamb" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Jamb" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Jamb" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Jamb" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Jamb")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={jambimage} alt="Jamb" style={{width:"30px",height:"30px"}}  />
                </ListItemIcon>
                <ListItemText
                  primary="Jamb"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Glass" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Glass" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Glass" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Glass" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Glass")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={glassimage} alt="Jamb" style={{height: "25px", width: "25px"}} />

                </ListItemIcon>
                <ListItemText
                  primary="Glass"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Colors" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Colors" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Colors" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Colors" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Colors")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  <FormatColorFillIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Colors"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Fitting" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Fitting" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Fitting" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Fitting" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Fitting")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  <RoomPreferencesIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Fitting"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Threshold" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Threshold" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Threshold" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Threshold" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Threshold")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  <BalconyIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Threshold"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Kits" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Kits" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Kits" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Kits" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Kits")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={handleimage} alt="Jamb" style={{width:"25px",height:"25px"}}  />

                </ListItemIcon>
                <ListItemText
                  primary="Kits"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Divider /> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Misc" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Misc" ? "#89292b" : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Misc" ? "#d06264" : "#9d2f31", // Different hover color
                    color: activeCategory !== "Misc" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Misc")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  <BalconyIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Misc"
                  sx={{
                    minWidth: 0,
                    textAlign: "center", // Ensures text is centered
                    display: "flex", // Allows centering text within the container
                    flexDirection: "column", // Makes sure text is aligned in column layout
                    alignItems: "center", // Centers text horizontally
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color: activeCategory === "Installation" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Installation"
                      ? "#89292b"
                      : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Installation" ? "#d06264" : "#9d2f31", // Different hover color
                    color:
                      activeCategory !== "Installation" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Installation")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mb: 0.5, // Adds space between icon and text
                    justifyContent: "center",
                    display: "flex", // Ensures the icon is centered
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={installtion} alt="installtion" style={{width:"35px",height:"35px"}}  />

                </ListItemIcon>
                <ListItemText
                  primary="Installation"
                  sx={{
                    minWidth: 0,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  boxShadow: 3,
                  borderRadius: 2,
                  marginRight: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  minWidth: open ? 50 : 50,
                  color:
                    activeCategory === "Enquiry/Export" ? "#fff" : "#1a1919", // Change text color if active
                  backgroundColor:
                    activeCategory === "Enquiry/Export"
                      ? "#89292b"
                      : "transparent", // Change background if active
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor:
                      activeCategory !== "Enquiry/Export"
                        ? "#d06264"
                        : "#9d2f31", // Different hover color
                    color:
                      activeCategory !== "Enquiry/Export" ? "#ffffff" : "#fff",
                  },
                  justifyContent: "center",
                  px: 2.5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => handleCategoryClick("Enquiry/Export")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mb: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <BalconyIcon /> */}
                  <img src={enquiry} alt="enquiry" style={{width:"25px",height:"25px"}}  />

                  
                </ListItemIcon>
                <CustomListItemText topText="Enquiry" bottomText="Export" />
              </ListItemButton>
            </ListItem>
          </List>
          <Box
            sx={{
              position: "relative",
              width: "1px",
              bgcolor: "divider",
              top: 0,
              bottom: 0,
              // height: "100vh",
              overflowY: "auto",
            }}
          />
          {/* Middle Section */}
          {open && (
            <Box
            sx={{
              width: "900px",
              height: "calc(100vh - 64px)", // Same fixed height as the Drawer (excluding toolbar height)
              overflowY: "auto", // Scroll for the Middle Content when it overflows
              padding: theme.spacing(2),
              "&::-webkit-scrollbar": {
                display: "block",
              },
            }}
          >
              {renderMiddlePanelContent()}
            </Box>
          )}
        </Box>
      </Drawer>

      {/* Right Side Section */}
      <Box
        component="aside"
        sx={{
          flexGrow: 1,
          // p: 3,
          p: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 2,
          },

          backgroundColor: "#f5f5f5",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: open ? 0 : 0,
          borderLeft: open ? "1px solid #ddd" : "none",
        }}
      >
        <DrawerHeader />

        {/* <Typography variant="h6">Right Side Section</Typography> */}
        {renderRightPanelContent()}

        <Button
          color="inherit"
          aria-label="open drawer"
          onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
          sx={{
            position: "fixed",
            bottom: 16,
            left: open ? 500 : 10,
            zIndex: 1000,
            backgroundColor: "#5F1C1D",
            color: "white",
            borderRadius: "4px",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#9d2f31",
            },
          }}
        >
          <MenuIcon />
        </Button>
        {/* <Doorstyle /> */}

      <MyComponent />  


 
      </Box>
    </Box>
  );
}
