import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { CheckCircle, Hardware } from "@mui/icons-material";
import { useColorContext } from "../ColorContext";
import "../Door/Doorcss.css";
import "../Door/Doubledoor.css";
import { styled } from "@mui/material/styles";

import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Slider,
  TextField,
  MenuItem,
  Select
} from "@mui/material";

const KIT_COLOR = "#5F1C1D";
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  "&.Mui-checked": {
    color: KIT_COLOR,
  },
});



interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}

interface DoorcomProps {
  onSelect: (value: any) => void; // Change 'any' to the appropriate type if known
}

const DoorType = ({
  onSelect,
}: {
  onSelect: (component: React.ReactNode) => void;
}) => {

  const [configIndex, setConfigIndex] = useState<number>(0);
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
  const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
  const navigate = useNavigate(); // For navigation after save

  const [selectedSprite, setSelectedSprite] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedDoor,savedata } = useColorContext() as any;
  const [showInput, setShowInput] = useState<string>("singledoor");

  // Ensure safe default values if localStorage items are undefined or null
  const [width, setWidth] = useState<number | null>(() => {
    const storedWidth = localStorage.getItem("width");
    return storedWidth ? Number(storedWidth) : null;
  });

  const [height, setHeight] = useState<number | null>(() => {
    const storedHeight = localStorage.getItem("height");
    return storedHeight ? Number(storedHeight) : null;
  });

  const [unitCost, setUnitCost] = useState<number>(() => Number(localStorage.getItem("unitCost")) || 60);
  const [unitCost1, setUnitCost1] = useState<number>(() => Number(localStorage.getItem("unitCost1")) || 0);
  const [TransformandSlidelight, setTransformandSlidelight] = useState<string>(() => String(localStorage.getItem("TransformandSlidelight")) || '');
  const [doorSwing, setDoorSwing] = useState<string>(() => String(localStorage.getItem("doorSwing")) || '');
  const [hardwareType, setHardwareType] = useState<string>(() => String(localStorage.getItem("hardwareType")) || '');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Handle saving values to localStorage on change
  useEffect(() => {
    localStorage.setItem("width", (width ?? 0).toString());
  }, [width]);

  useEffect(() => {
    localStorage.setItem("height", (height ?? 0).toString());
  }, [height]);

  useEffect(() => {
    localStorage.setItem("unitCost", unitCost.toString());
  }, [unitCost]);

  useEffect(() => {
    localStorage.setItem("unitCost1", unitCost1.toString());
  }, [unitCost1]);

  useEffect(() => {
    localStorage.setItem("TransformandSlidelight", TransformandSlidelight.toString());
  }, [TransformandSlidelight]);

  useEffect(() => {
    localStorage.setItem("hardwareType", hardwareType.toString());
  }, [hardwareType]);

  useEffect(() => {
    localStorage.setItem("doorSwing", doorSwing.toString());
  }, [doorSwing]);

  const handleSpriteClick = (gate: string) => {
    setSelectedSprite(gate);
    setSelectedDoor(gate);
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(Number(event.target.value));

     setEditedConfig((prev) => ({ ...prev, width: Number(event.target.value) })); // Update the editedConfig


  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(Number(event.target.value));
    setEditedConfig((prev) => ({ ...prev, height: Number(event.target.value) })); // Update the editedConfig

  };

  const handleUnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost(Number(event.target.value));
    setEditedConfig((prev) => ({ ...prev, unitCost : Number(event.target.value) }));
  };

  const handleUnitCostChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnitCost1(Number(event.target.value));
  };

  const handleWidthSliderChange = (event: Event, newValue: number | number[]) => {
    const newWidth = newValue as number;
    setWidth(newWidth); // Update the width state
    setEditedConfig((prev) => ({ ...prev, width: newWidth })); // Update the editedConfig

  // const doorsqrfeet = ((width ?? 0) * (height ?? 0)) / 144;
  // setEditedConfig((prev) => ({ ...prev, sqftLft: doorsqrfeet })); // Update the editedConfig


};

const handleHeightSliderChange = (event: Event, newValue: number | number[]) => {
    const newHeight = newValue as number;
    setHeight(newHeight); // Update the height state
    setEditedConfig((prev) => ({ ...prev, height: newHeight })); // Update the editedConfig
    
    
};


// const handleWidthSliderChange = (  event: Event,  newValue: number | number[]) => {
//   setWidth(newValue as number);
// };

// const handleHeightSliderChange = (  event: Event,  newValue: number | number[]) => {
//   setHeight(newValue as number);
// };

  // Calculating estimated cost
  const doorsqrfeet = ((width ?? 0) * (height ?? 0)) / 144;
  const Area = doorsqrfeet;
  const UnitCost = unitCost;
  const Estimatedcost = Area * UnitCost;
  const Price = Estimatedcost * 2.5;
  const EstimatedCost1 = Estimatedcost * 0.2;

  useEffect(() => {
    localStorage.setItem("Estimatedcost", JSON.stringify(EstimatedCost1));
    
    if (userId) {

    setEditedConfig((prev) => ({ ...prev, sqftLft: doorsqrfeet.toFixed(2) })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, selections: hardwareType })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, estimatedCost: Estimatedcost.toFixed(2) })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, price: EstimatedCost1.toFixed(2) })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, selections: TransformandSlidelight })); // Update the editedConfig

  } else {
  }

   }, [EstimatedCost1,height,hardwareType,Estimatedcost,EstimatedCost1,TransformandSlidelight]);


  //////////////////////   firebase start   /////////

  const userId = savedata ;

  // Fetch the door config of the selected user from Firebase
  useEffect(() => {
    if (userId) {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);
        setEditedConfig(userData.doorConfig[0] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[2] || {}); // Initialize the 2nd door config
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  } else {
  }
  }, [userId]);

 

  
  
  const handleSave = () => {
    if (userId) {
    const userRef = ref(db, `users/${userId}/doorConfig`);
  
    // Create an updates object with string keys for doorConfig
    const updates: { [key: string]: DoorConfig } = {};
    updates["0"] = editedConfig;  // Use string keys for doorConfig
    updates["2"] = editedConfig2;
  
    // Log values to check what is being updated
  
    // Perform the update in Firebase
    update(userRef, updates)
      .then(() => {
        alert('Door configurations updated successfully!');
        navigate('/'); // Navigate back to the main page
      })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
    } else {
    }
  };
  

  


   // setWidth(editedConfig.width) 

  const calculatedSqurftData = ((width ?? 0) * (height ?? 0)) / 144;

  useEffect(() => {
    if (editedConfig) {
      // Only update state if not already set (i.e., null or 0)
      if (width === null || width === 0) setWidth(editedConfig.width || 0);
      if (height === null || height === 0) setHeight(editedConfig.height || 0);
      if (unitCost === 60 || unitCost === 0 || unitCost === null) setUnitCost(editedConfig.unitCost || 60);
      if (hardwareType === "" || hardwareType === "null") setHardwareType(editedConfig.selections || "");
      if (TransformandSlidelight === "" || TransformandSlidelight === "null") setTransformandSlidelight(editedConfig2.selections || "");
    }
  
    // Directly calculate the square footage
  
    // Update squrftdata state
  
    // Update editedConfig with the new square footage
  
  }, [editedConfig, editedConfig2, width, height, unitCost, hardwareType, TransformandSlidelight]);
  
  
  /////////////////////   firebase end   /////////////

   return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white' }}>
        Door Hardware & Size
      </Typography>

      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
        <Select
          value={hardwareType}
          onChange={(e) => setHardwareType(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=""><em>Select Hardware Type</em></MenuItem>
          <MenuItem value="LOW PROFILE">Low Profile</MenuItem>
          <MenuItem value="HAND FORGED">Hand Forged</MenuItem>
          <MenuItem value="SLIDING">Sliding</MenuItem>
          <MenuItem value="FOLDING">Folding</MenuItem>
        </Select>
      </FormControl>

      {hardwareType !== '' && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography>Width</Typography>
          <input
  type="number"
  min="0"
  step="0.5"
  name="width" // Make sure the name matches the key in your config
  value={width ?? 0} 
  onChange={handleWidthChange}
   style={{
    textAlign: "center",
    width: "80px",
    marginRight: "8px",
    fontWeight: "bold",
    border: "1px solid #ccc",
    borderRadius: "4px",
    height: "35px",
    fontSize: "16px",
  }}
/>


          <Slider
            aria-label="Width"
            value={width}
            onChange={handleWidthSliderChange}
            step={1}
            min={0}
            max={1110}
            sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
          />

          <Typography>Height</Typography>
          <input
            type="number"
            min="0"
            step="0.5"
            value={height ?? 0}
            onChange={handleHeightChange}
            style={{
              textAlign: "center",
              width: "80px",
              marginRight: "8px",
              fontWeight: "bold",
              border: "1px solid #ccc",
              borderRadius: "4px",
              height: "35px",
              fontSize: "16px",
            }}
          />
          <Slider
            aria-label="Height"
            value={height}
            onChange={handleHeightSliderChange}
            step={1}
            min={0}
            max={1110}
            sx={{ color: KIT_COLOR, width: "300px", marginLeft: "8px" }}
          />

          <Box mt={2} display="flex" alignItems="center">
            <TextField
              label="Sqr/ft"
              value={doorsqrfeet.toFixed(2)}
              InputProps={{ readOnly: true }}
              variant="outlined"
              style={{ marginRight: 16 }}
              InputLabelProps={{ style: { color: KIT_COLOR } }}
            />
            <TextField
              label="Unit Cost"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={unitCost}
              onChange={handleUnitCostChange}
              inputProps={{ step: "1" }}
              style={{ width: "100%" }}
              InputLabelProps={{ style: { color: KIT_COLOR } }}
            />
          </Box>
        </Box>
      )}

      <Box>
        <Typography variant="h6" align="center" gutterBottom sx={{ backgroundColor: "#5F1C1D", color: 'white', marginTop: '20px' }}>
          Door Transform and Slidelight
        </Typography>
        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
          <Select
            value={TransformandSlidelight}
            onChange={(e) => setTransformandSlidelight(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value=""><em>Select Transform and Slidelight</em></MenuItem>
            <MenuItem value="W/SIDELIGHTS">W/SIDELIGHTS</MenuItem>
            <MenuItem value="W/TRANSOM">W/TRANSOM</MenuItem>
            <MenuItem value="W/SIDELIGHTS & TRANSOM">W/SIDELIGHTS & TRANSOM</MenuItem>
            <MenuItem value="W/OPERABLE GLASS">W/OPERABLE GLASS</MenuItem>
            <MenuItem value="W/SIMULATED DIVIDED LIGHTS">W/SIMULATED DIVIDED LIGHTS</MenuItem>
            <MenuItem value="W/TRUE DIVIDED LIGHTS">W/TRUE DIVIDED LIGHTS</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />
    </Box>
  );
};

export default DoorType;
