import   React , {useState,useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { useColorContext } from "../ColorContext";


import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}




const KIT_COLOR = '#5F1C1D';

// Custom Radio component to apply color
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  '&.Mui-checked': {
    color: KIT_COLOR,
  },
});

const Kitsscom = ({ onSelect }: { onSelect: (component: React.ReactNode) => void }) => {
  // Initialize state
  const [trimenterior1RadioValue, setTrimenterior1RadioValue] = React.useState<boolean>(() => {
    const savedValue = localStorage.getItem('trimenterior1RadioValue');
    return savedValue === null ||  savedValue === '' ? false : savedValue === 'true';
  });
    const [trimenterior1Width, setTrimenterior1Width] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1Width')) );
  const [trimenterior1Height, setTrimenterior1Height] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1Height'))  );
  const [trimenterior1UnitCost, setTrimenterior1UnitCost] = React.useState<number>(() => Number(localStorage.getItem('trimenterior1UnitCost')) || 8);

  const [trimexterior2RadioValue, setTrimexterior2RadioValue] = React.useState<boolean>(() => {const savedValue = localStorage.getItem('trimexterior2RadioValue');
  return savedValue === null || savedValue === '' ? false : savedValue === 'true';
});
  const [trimexterior2Width, setTrimexterior2Width] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2Width'))  );
  const [trimexterior2Height, setTrimexterior2Height] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2Height'))  );
  const [trimexterior2UnitCost, setTrimexterior2UnitCost] = React.useState<number>(() => Number(localStorage.getItem('trimexterior2UnitCost')) ||  8);

  const [squareft,setSquareft ]= useState<number | null>(null);
  const [squareft2,setSquareft2 ]= useState<number | null>(null);
  
  const { savedata} = useColorContext() as any;

  const handleTrimenterior1RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setTrimenterior1RadioValue(value === 'true' );


   localStorage.setItem('trimenterior1RadioValue', value);
  };

  const handleTrimenterior1WidthChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimenterior1Width(value);
    localStorage.setItem('trimenterior1Width', value.toString());
  };

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimenterior1Width(newValue);
      localStorage.setItem('trimenterior1Width', newValue.toString());
    }
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimenterior1Height(newValue);
      localStorage.setItem('trimenterior1Height', newValue.toString());
    }
  };

  const handleTrimenterior1HeightChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimenterior1Height(value);
    localStorage.setItem('trimenterior1Height', value.toString());
  };

  const handleTrimenterior1UnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value)  ;
    setTrimenterior1UnitCost(newValue);
    localStorage.setItem('trimenterior1UnitCost', newValue.toString());
  };


  React.useEffect(() => {
    localStorage.setItem('trimenterior1UnitCost',trimenterior1UnitCost.toString());
  }, [trimenterior1UnitCost]); 

  React.useEffect(() => {
    localStorage.setItem('trimexterior2UnitCost',trimexterior2UnitCost.toString());
  }, [trimexterior2UnitCost]); 



  const handleTrimexterior2RadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setTrimexterior2RadioValue(value === "true"); // Returns true if value is "true", otherwise false
    localStorage.setItem('trimexterior2RadioValue', value);
  };
  

  const handleTrimexterior2WidthChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimexterior2Width(value);
    localStorage.setItem('trimexterior2Width', value.toString());
  };

  const handleChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimexterior2Width(newValue);
      localStorage.setItem('trimexterior2Width', newValue.toString());
    }
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setTrimexterior2Height(newValue);
      localStorage.setItem('trimexterior2Height', newValue.toString());
    }
  };

  const handleTrimexterior2HeightChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number;
    setTrimexterior2Height(value);
    localStorage.setItem('trimexterior2Height', value.toString());
  };

  const handleTrimexterior2UnitCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value)  ;
    setTrimexterior2UnitCost(newValue);
    localStorage.setItem('trimexterior2UnitCost', newValue.toString());
  };

  function calculateValue(width: number, height: number) {
    return (width + height+ height) / 12;
  }


  const handleOptionClick = (option: string) => {
    onSelect(option);  // Example usage, if `onSelect` is a prop function 
  };




  
    

  //////////////////////   firebase start   /////////






  useEffect(() => {

    setSquareft(parseFloat(((trimenterior1Width + trimenterior1Height + trimexterior2Height) / 12).toFixed(2)));
    setSquareft2(parseFloat(((trimexterior2Width + trimexterior2Height + trimexterior2Height) / 12).toFixed(2)));

    if (userId) {

 

    setEditedConfig((prev) => ({ ...prev, selections: trimenterior1RadioValue })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, width: trimenterior1Width })); // Update the editedConfig 
    setEditedConfig((prev) => ({ ...prev, height: trimenterior1Height })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, sqftLft: squareft })); // Update the editedConfig 
    setEditedConfig((prev) => ({ ...prev, unitCost: trimenterior1UnitCost })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, estimatedCost: (squareft ?? 0) * trimenterior1UnitCost })); // Update the editedConfig
    setEditedConfig((prev) => ({ ...prev, price: ( (squareft ?? 0) * trimenterior1UnitCost)*2.5 })); // Update the editedConfig
 

 
    setEditedConfig2((prev) => ({ ...prev, selections: trimexterior2RadioValue })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, width: trimexterior2Width })); // Update the editedConfig 
    setEditedConfig2((prev) => ({ ...prev, height: trimexterior2Height })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, sqftLft: squareft2 })); // Update the editedConfig 
    setEditedConfig2((prev) => ({ ...prev, unitCost: trimexterior2UnitCost })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, estimatedCost:squareft2 ?? 0 * trimexterior2UnitCost })); // Update the editedConfig
    setEditedConfig2((prev) => ({ ...prev, price: (squareft2 ?? 0 * trimexterior2UnitCost)* 2.5 })); // Update the editedConfig

            
                                                                                
  } else {
  }
    
  }, [   trimexterior2Width,trimexterior2Width,trimenterior1RadioValue,trimenterior1Width,trimenterior1Height,squareft,trimenterior1UnitCost,
    trimexterior2RadioValue, trimexterior2Height,squareft2,trimexterior2UnitCost
         
    
    ]);








  const [configIndex, setConfigIndex] = useState<number>(0);
  const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
  const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
  const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
 
  const navigate = useNavigate(); // For navigation after save





  const userId = savedata;

  // Fetch the door config of the selected user from Firebase
  useEffect(() => {
    const userRef = ref(db, `users/${userId}`);
    get(userRef).then((snapshot) => {
      const userData = snapshot.val();
      if (userData && userData.doorConfig) {
        setDoorConfigs(userData.doorConfig);

        setEditedConfig(userData.doorConfig[17] || {}); // Initialize the 0th door config
        setEditedConfig2(userData.doorConfig[18] || {}); // Initialize the 2nd door config
 
      }
    }).catch((error) => {
      alert('Failed to load door configuration');
    });
  }, [userId]);

 



  const handleSave = () => {
    const userRef = ref(db, `users/${userId}/doorConfig`);

    // Create an updates object with string keys for doorConfig
    const updates: { [key: string]: DoorConfig } = {};
    updates["17"] = editedConfig;  // Use string keys for doorConfig
    updates["18"] = editedConfig2;
 
 

    // Perform the update in Firebase
    update(userRef, updates)
      .then(() => {
        alert('Door configurations updated successfully!');
        // navigate('/'); // Navigate back to the main page
      })
      .catch((error) => {
        alert('Failed to update door configuration');
      });
  };





  // setWidth(editedConfig.width) 





  useEffect(() => {

    if (userId) {
    if (editedConfig) {

        if( trimenterior1RadioValue === false   ) { setTrimenterior1RadioValue(editedConfig.selections ==="Yes" || true  ? true : false   || false );}
       if( trimenterior1Width === null ||  trimenterior1Width === 0  )
        
        { setTrimenterior1Width(editedConfig.width || null );}
       if( trimenterior1Height === null ||  trimenterior1Height === 0  ) { setTrimenterior1Height(editedConfig.height || null );}
       if( squareft === null  ) {  setSquareft(editedConfig.sqftLft  || null ) }
      if( trimenterior1UnitCost === 8 ||  trimenterior1UnitCost === 0  ) { setTrimenterior1UnitCost(editedConfig.unitCost || 8 );}
    
     }

    if (editedConfig2) {

      if( trimexterior2RadioValue === false   ) { setTrimexterior2RadioValue(editedConfig2.selections ==="Yes" || true  ? true : false   || false );}
      if( trimexterior2Width === null ||  trimexterior2Width === 0  ) { setTrimexterior2Width(editedConfig2.width || null );}
      if( trimexterior2Height === null ||  trimexterior2Height === 0  ) { setTrimexterior2Height(editedConfig2.height || null );}
      if( squareft2 === null  )      {   setSquareft2(editedConfig2.sqftLft  || null ) }
     if( trimexterior2UnitCost === 8 ||  trimexterior2UnitCost === 0  ) { setTrimexterior2UnitCost(editedConfig2.unitCost || 8 );}  

    }
 
  } else {
  }


  }, [editedConfig, editedConfig2,trimexterior2Height,  trimenterior1RadioValue,trimenterior1Width,trimenterior1Height,squareft,trimenterior1UnitCost,trimexterior2RadioValue, trimexterior2Width,squareft2
   ]);
  

  /////////////////////   firebase end   ///////////////





 

 


  return (
    <Box sx={{p:1}}>
      {/* Trimenterior1 Button */}
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
     Kits
      </Typography>
      <Box>
      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Trim Exterior
      </Typography>
        <Box display="flex" alignItems="center" mb={1}>
 
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#f9f9f9',
            padding: 2,
          }}
        >
          <FormControl component="fieldset"> 
            <RadioGroup
              aria-label="trim-interior-trimenterior1"
              name="trim-interior-trimenterior1"
              value={trimenterior1RadioValue}
              onChange={handleTrimenterior1RadioChange}
            >
              <Box>
                <FormControlLabel
                  value='true'
                  control={<CustomRadio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<CustomRadio />}
                  label="No"
                />
              </Box>
            </RadioGroup>

            {trimenterior1RadioValue   && (
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                 <Typography>Width</Typography>
                  <input
                    type="number" 
                    min="0"
                    step="0.5" 
                    id="textInput"
                    value={trimenterior1Width}
                    max={1110}
                    onChange={handleChange1}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Width"
                    value={trimenterior1Width}
                    onChange={handleTrimenterior1WidthChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Height</Typography>
                 
                  <input
                    type="number" 
                    min="0"
                    step="0.5" 
                    id="textInput"
                    value={trimenterior1Height}
                    max={1110}
                    onChange={handleChange2}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Height"
                    value={trimenterior1Height}
                    onChange={handleTrimenterior1HeightChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box mt={2} display="flex" alignItems="center">
  <TextField
    label="Sqr/ft"
    value={squareft}
    InputProps={{ readOnly: true }}
    variant="outlined"
    style={{ marginRight: 16 }}
    InputLabelProps={{ style: { color: KIT_COLOR } }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'defaultBorderColor', // Default border color
        },
        '&:hover fieldset': {
          borderColor: 'defaultBorderColor', // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: KIT_COLOR, // Border color when focused
        },
      },
    }}
    autoFocus  // This will keep the input focused
  />
  <TextField
    label="Unit Cost"
    value={trimenterior1UnitCost}
    onChange={handleTrimenterior1UnitCostChange}
    variant="outlined"
    type="number"
    InputProps={{ inputProps: { min: 0 } }}
    inputProps={{ step: '0.01' }}
    InputLabelProps={{ style: { color: KIT_COLOR } }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'defaultBorderColor',
        },
        '&:hover fieldset': {
          borderColor: 'defaultBorderColor',
        },
        '&.Mui-focused fieldset': {
          borderColor: KIT_COLOR,
        },
      },
    }}
  />
</Box>

              </Box>
            )}
          </FormControl>
        </Box>
      </Box>

      <Box mt={2}>


      <Typography
        variant="h6"
        sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2,mb:2 }}
      >
        Trim Interior
      </Typography> 
      
        <Box display="flex" alignItems="center" mb={1}>
 
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#f9f9f9',
            padding: 2,
          }}
        >
          <FormControl component="fieldset">
 
            <RadioGroup
              aria-label="trim-interior-trimexterior2"
              name="trim-interior-trimexterior2"
              value={trimexterior2RadioValue}
              onChange={handleTrimexterior2RadioChange}
            >
              <Box>
                <FormControlLabel
                  value="true"
                  control={<CustomRadio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<CustomRadio />}
                  label="No"
                />
              </Box>
            </RadioGroup>

            {trimexterior2RadioValue   && (
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Width</Typography>
                  <input
                    type="number" 
                    min="0"
                    step="0.5" 
                    id="textInput"
                    value={trimexterior2Width}
                    max={1110}
                    onChange={handleChange4}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Width"
                    value={trimexterior2Width}
                    onChange={handleTrimexterior2WidthChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Typography>Height</Typography>
                 
                  <input
                    type="number" 
                    min="0"
                    step="0.5" 
                    id="textInput"
                    value={trimexterior2Height}
                    max={1110}
                    onChange={handleChange3}
                    style={{
                      textAlign: 'center',
                      width: '80px',
                      marginRight: '8px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      height: '35px',
                      fontSize: '16px'
                    }}
                  />
                  <Slider
                    aria-label="Height"
                    value={trimexterior2Height}
                    onChange={handleTrimexterior2HeightChange}
                    getAriaValueText={(value:any) => `${value} units`}
                    step={1}
                    min={0}
                    max={1110}
                    sx={{ color: KIT_COLOR }}
                  />
                </Box>
                <Box mt={2} display="flex" alignItems="center">
  <TextField
    label="Sqr/ft"
    value={squareft2}
    InputProps={{ readOnly: true }}
    variant="outlined"
    style={{ marginRight: 16 }}
    InputLabelProps={{ style: { color: KIT_COLOR } }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'defaultBorderColor', // Default border color
        },
        '&:hover fieldset': {
          borderColor: 'defaultBorderColor', // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: KIT_COLOR, // Border color when focused
        },
      },
    }}
    autoFocus  // This will keep the input focused
  />
  <TextField
    label="Unit Cost"
    value={trimexterior2UnitCost}
    onChange={handleTrimexterior2UnitCostChange}
    variant="outlined"
    type="number"
    InputProps={{ inputProps: { min: 0 } }}
    inputProps={{ step: '0.01' }}
    InputLabelProps={{ style: { color: KIT_COLOR } }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'defaultBorderColor', // Default border color
        },
        '&:hover fieldset': {
          borderColor: 'defaultBorderColor', // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: KIT_COLOR, // Border color when focused
        },
      },
    }}
  />
</Box>

              </Box>
            )}
          </FormControl>
        </Box>
      </Box>


      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />


    </Box>
  );
};

export default Kitsscom;
