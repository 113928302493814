// import React, { useState, useEffect } from 'react';
// import './UserTable.css';  // CSS file import
// import { db } from '../Enquiry/firebase'; // Import the 'db' from Firebase configuration
// import { ref, onValue, remove } from 'firebase/database';
// import { Icon } from '@iconify/react'; // Import Iconify component
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
//   Paper, Box, Typography
// } from '@mui/material';  // Material UI components
// const UserTable = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);  // State to manage login status
//   const [users, setUsers] = useState([]); // State to store users
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [isVisible2, setIsVisible2] = useState(false);
//   const [userselectid, setUserselectid] = useState('');
//   // Static UserID and Password for login
//   const staticUserId = "admin";
//   const staticPassword = "pass";userId
//   // Handle login form submission
//   const handleLogin = (e) => {
//     e.preventDefault();
//     const userId = e.target.userId.value;
//     const password = e.target.password.value;
//     // If credentials match, set logged-in state to true and load user data
//     if (userId === staticUserId && password === staticPassword) {
//       setIsLoggedIn(true);
//     } else {
//       alert("Invalid credentials, please try again.");
//     }
//   };
//   ////////////  get firebase data start ///////
//   useEffect(() => {
//     const usersRef = ref(db, 'users');
//     const unsubscribe = onValue(usersRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const usersArray = Object.keys(data).map(key => ({
//           id: key,
//           firstName: data[key].userInfo.firstName,
//         }));
//         setUsers(usersArray);
//       } else {
//         setUsers([]);
//       }
//     });
//     return () => unsubscribe();
//   }, []);
//   // Handle the user click to fetch their full details
//   const handleUserClick = (userId) => {
//     const userRef = ref(db, `users/${userId}`);
//     onValue(userRef, (snapshot) => {
//       const userData = snapshot.val();
//       setSelectedUser(userData);
//     });
//   };
//   // Handle delete user
//   const handleDeleteUser = (userId) => {
//     setUserselectid(userId);
//     setIsVisible2(true);
//   };
//   const handleDelete = () => {
//     setIsVisible2(false); // Close popup on confirm
//     const userRef = ref(db, `users/${userselectid}`);
//     remove(userRef).catch((error) => {
//       console.error('Error deleting user:', error);
//       alert('Failed to delete user');
//     });
//   };
//   const handleCancel = () =>{ setIsVisible2(false);} // Close popup on cancel
//   ///////////  end firebase data  ///////
//   return (
//     <div style={{ backgroundColor: "#5f1c1d", minHeight: "100vh", position: "relative" }}>
//       <div className="user-table-container">
//         {!isLoggedIn ? (
//           <div className="login-form-container">
//             <h2>Login</h2>
//             <form onSubmit={handleLogin} className="login-form">
//               <div className="input-group">
//                 <label htmlFor="userId">UserID</label>
//                 <input type="text" id="userId" required />
//               </div>
//               <div className="input-group">
//                 <label htmlFor="password">Password</label>
//                 <input type="password" id="password" required />
//               </div>
//               <button type="submit" className="login-button">Login</button>
//             </form>
//           </div>
//         ) : (
//           <div className="data-container">
//             {/* Delete popup */}
//             {isVisible2 && (
//               <div className="popup-overlay">
//                 <div className="delete-popup">
//                   <h2 className="delete-popup__title">Are you sure you want to delete? 🗑️</h2>
//                   <p className="delete-popup__description">
//                     Once deleted, this action cannot be undone. Please confirm.
//                   </p>
//                   <div className="delete-popup__buttons">
//                     <button className="delete-popup__button--confirm" onClick={handleDelete}>
//                       Yes, Delete 👍
//                     </button>
//                     <button className="delete-popup__button--cancel" onClick={handleCancel}>
//                       No, Cancel 👎
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//             <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
//               <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>
//                 User Information
//               </Typography>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ddd' }}>
//                       First Name
//                     </TableCell>
//                     <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff' }}>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {users.map((user) => (
//                     <TableRow key={user.id}>
//                       <TableCell onClick={() => handleUserClick(user.id)} sx={{ borderRight: '1px solid #ddd' }}>
//                         {user.firstName}
//                       </TableCell>
//                       <TableCell>
//                         <Box onClick={() => alert('Edit functionality is a placeholder')} style={{ display: 'inline-flex', marginRight: '10px' }}>
//                           <Icon icon="ic:round-edit" width="24" height="24" style={{ color: "#154eff" }} />
//                         </Box>
//                         <Box onClick={() => handleDeleteUser(user.id)} style={{ display: 'inline-flex' }}>
//                           <Icon icon="ic:baseline-delete" width="24" height="24" style={{ color: "#c60000" }} />
//                         </Box>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             {selectedUser && (
//               <div>
//                 {/* User Info */}
//                 <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>User Information</Typography>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>Field</TableCell>
//                       <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>Value</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {Object.entries(selectedUser.userInfo).map(([key, value]) => (
//                       <TableRow key={key}>
//                         <TableCell sx={{ borderRight: '1px solid #ccc' }}>{key}</TableCell>
//                         <TableCell>{value}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//                 {/* Door Config Table */}
//                 <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>Door Configuration</Typography>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       {['Category', 'Selections', 'Width (Inches)', 'Height (Inches)', 'Sqft/Lft', 'Unit Cost', 'Estimated Cost', 'Price'].map((header) => (
//                         <TableCell key={header} sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>
//                           {header}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {selectedUser.doorConfig.map((door, index) => (
//                       <TableRow key={index}>
//                         {Object.values(door).map((value, idx) => (
//                           <TableCell key={idx} sx={{ borderRight: '1px solid #ccc' }}>{value}</TableCell>
//                         ))}
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
// export default UserTable;











import React, { useState, useEffect } from 'react';
import './UserTable.css';  // CSS file import
import { db } from '../Enquiry/firebase'; // Import the 'db' from Firebase configuration
import { ref, onValue, update, remove } from 'firebase/database';
import { Icon } from '@iconify/react'; // Import Iconify component
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';  // Material UI components
 

const UserTable = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // State to manage login status
  const [users, setUsers] = useState([]); // State to store users
  const [selectedUser, setSelectedUser] = useState(null);
  const [isVisible2, setIsVisible2] = useState(false);
  const [userselectid, setUserselectid] = useState('');
  const [isEditing, setIsEditing] = useState(false); // State to track if editing is enabled
  const [editedUser, setEditedUser] = useState({}); // State to store edited user data
  const [editedDoorConfig, setEditedDoorConfig] = useState([]); // State to store edited door configurations
  const [originalUser, setOriginalUser] = useState({}); // To store original user data for cancel functionality

  // Static UserID and Password for login
  const staticUserId = "admin";
  const staticPassword = "pass";

  // Handle login form submission
  const handleLogin = (e) => {
    e.preventDefault();
    const userId = e.target.userId.value;
    const password = e.target.password.value;

    // If credentials match, set logged-in state to true and load user data
    if (userId === staticUserId && password === staticPassword) {
      setIsLoggedIn(true);
    } else {
      alert("Invalid credentials, please try again.");
    }
  };

  ////////////  get firebase data start ///////
  useEffect(() => {
    const usersRef = ref(db, 'users');

    const unsubscribe = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const usersArray = Object.keys(data).map(key => ({
          id: key,
          firstName: data[key].userInfo.firstName,
        }));
        setUsers(usersArray);
      } else {
        setUsers([]);
      }
    });

    return () => unsubscribe();
  }, []);

  // Handle the user click to fetch their full details
  const handleUserClick = (userId) => {
    const userRef = ref(db, `users/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      setSelectedUser(userData);
      setEditedUser(userData.userInfo);
      setEditedDoorConfig(userData.doorConfig);
      setOriginalUser(userData); // Save the original user data for cancel functionality
    });
  };

  // Handle delete user
  const handleDeleteUser = (userId) => {
    setUserselectid(userId);
    setIsVisible2(true);
  };

  const handleDelete = () => {
    setIsVisible2(false); // Close popup on confirm
    const userRef = ref(db, `users/${userselectid}`);
    remove(userRef).catch((error) => {
      console.error('Error deleting user:', error);
      alert('Failed to delete user');
    });
  };

  const handleCancel = () => { setIsVisible2(false); } // Close popup on cancel

  ///////////  end firebase data  ///////

  // Handle editing user information
  const handleEditClick = (userId) => {
    setIsEditing(true);
    // setSelectedUser(true);
    const userRef = ref(db, `users/${userId}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      setSelectedUser(userData);
      setEditedUser(userData.userInfo);
      setEditedDoorConfig(userData.doorConfig);
      setOriginalUser(userData); // Save the original user data for cancel functionality
    });
   };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle saving edited user data
  const handleSaveClick = () => {
    const userRef = ref(db, `users/${selectedUser.id}`);
    update(userRef, {
      userInfo: editedUser,
      doorConfig: editedDoorConfig,
    }).then(() => {
      setIsEditing(false);
      setSelectedUser((prevState) => ({
        ...prevState,
        userInfo: editedUser,
        doorConfig: editedDoorConfig,
      }));
    }).catch((error) => {
      console.error('Error updating user:', error);
      alert('Failed to save changes');
    });
  };

  // Handle canceling the changes
  const handleCancelEdit = () => {
    setEditedUser(originalUser.userInfo);  // Reset to original user data
    setEditedDoorConfig(originalUser.doorConfig);  // Reset to original door config
    setIsEditing(false);  // Disable editing mode
  };

  // Handle editing door configuration
  const handleDoorConfigEdit = (index, field, value) => {
    const updatedDoorConfig = [...editedDoorConfig];
    updatedDoorConfig[index][field] = value;
    setEditedDoorConfig(updatedDoorConfig);
  };



  const rowSpecificOptions = [
    ['HAND FORGED', 'LOW PROFILE', 'SLIDING', 'FOLDING'],
    ['SINGLE DOOR', 'DOUBLE DOOR', 'FOLDING DOOR', 'SLIDING DOOR'],
    ["W/SIDELIGHTS", "W/TRANSOM", "W/SIDELIGHT & TRANSOM", "W/OPERABLE GLASS", "W/SIMULATED DIVIDED LIGHTS", "W/TRUE DIVIDED LIGHTS"],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['1', '1 1/2', '2'],
    ['4', '4 1/2', '5', '5 1/2', '6', '6 1/2', 'TBD', 'CUSTOM'],
    ['1x2', '1 1/2x2', '2x2', '3x2', '4x2', "TBD", "CUSTOM"],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['0'],
    ['N/A', 'PULL HANDLES W/ROLLER LATCHES', 'STANDARD HARDWARE', 'TBD', 'CUSTOM'],
    ['0'],
    ['STEP', 'EXTENDED STEP', 'OUT SWING', 'ADA TOP OF FLOOR', 'ADA-BUTT FLOOR'],
    ['3/4', '1 1/2', '2', '2 1/2'],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['LOWE 1', 'LOWE 2', 'LOWE 3', 'FLEMISH', 'OPERABLE', 'FIXED'],
    ['TBD', 'CO1', 'CO2', 'CO3', 'CO4', 'CO5', 'CO6', 'CO7', 'CO8', 'CO9'],
    ['Yes', 'No'],
    ['Yes', 'No'],
    ['Yes', 'No'],
  ];


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;

  // Function to go to the next page
  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < users.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to go to the previous page
  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Slice the users array to only show the current page's data
  const visibleUsers = users.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);




  return (
    <div style={{ backgroundColor: "#5f1c1d", minHeight: "100vh", position: "relative" }}>
      <div className="user-table-container">
        {!isLoggedIn ? (
          <div className="login-form-container">
            <h2>Login</h2>
            <form onSubmit={handleLogin} className="login-form">
              <div className="input-group">
                <label htmlFor="userId">UserID</label>
                <input type="text" id="userId" required />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" required />
              </div>
              <button type="submit" className="login-button">Login</button>
            </form>
          </div>
        ) : (
          <div className="data-container">
            {/* Delete popup */}
            {isVisible2 && (
              <div className="popup-overlay">
                <div className="delete-popup">
                  <h2 className="delete-popup__title">Are you sure you want to delete? 🗑️</h2>
                  <p className="delete-popup__description">
                    Once deleted, this action cannot be undone. Please confirm.
                  </p>
                  <div className="delete-popup__buttons">
                    <button className="delete-popup__button--confirm" onClick={handleDelete}>
                      Yes, Delete 👍
                    </button>
                    <button className="delete-popup__button--cancel" onClick={handleCancel}>
                      No, Cancel 👎
                    </button>
                  </div>
                </div>
              </div>
            )}

<TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
      <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>
        User Information
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ddd' }}>
              First Name
            </TableCell>
            <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell onClick={() => handleUserClick(user.id)} sx={{ borderRight: '1px solid #ddd' }}>
                {user.firstName}
              </TableCell>
              <TableCell>
                <Box onClick={() => handleEditClick(user.id)} style={{ display: 'inline-flex', marginRight: '10px' }}>
                  <Icon icon="ic:round-edit" width="24" height="24" style={{ color: "#154eff" }} />
                </Box>
                <Box onClick={() => handleDeleteUser(user.id)} style={{ display: 'inline-flex' }}>
                  <Icon icon="ic:baseline-delete" width="24" height="24" style={{ color: "#c60000" }} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <Box sx={{ display: 'flex', justifyContent: 'right', margin: '10px 1px',gap:"25px"  }}>
        <button className='deleteButton' onClick={handlePrevPage}  disabled={currentPage === 0}  >Previous</button>
        <p className='pageNumber'>{currentPage+1}</p>
        <button  className='deleteButton' onClick={handleNextPage} disabled={(currentPage + 1) * itemsPerPage >= users.length}>Next</button>
      </Box>
    </TableContainer>

            {selectedUser && (
              <div>
                <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>
                  User Information
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>Field</TableCell>
                      <TableCell sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      'firstName', 'lastName', 'address', 'city', 'state', 'zipCode', 'yearBuilt', 'phone', 'cell', 'work'
                    ].map((key) => (
                      <TableRow key={key}>
                        <TableCell sx={{ borderRight: '1px solid #ccc' }}>{key}</TableCell>
                        <TableCell>
                          {isEditing ? (
                            <TextField
                              name={key}
                              value={editedUser[key]}
                              onChange={handleInputChange}
                              fullWidth
                            />
                          ) : (
                            editedUser[key]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>

                {/* Door Config Table */}
                <Typography variant="h6" sx={{ textAlign: 'center', padding: '10px' }}>Door Configuration</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      {['Category', 'Selections', 'Width (Inches)', 'Height (Inches)', 'Sqft/Lft', 'Unit Cost', 'Estimated Cost', 'Price'].map((header) => (
                        <TableCell key={header} sx={{ backgroundColor: '#5f1c1d', color: '#fff', borderRight: '1px solid #ccc' }}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
  {editedDoorConfig.map((door, index) => (
    <TableRow key={index}>
      {[
        'category',
        'selections',
        'width',
        'height',
        'sqftLft',
        'unitCost',
        'estimatedCost',
        'price'
      ].map((field, idx) => (
        <TableCell key={idx} sx={{ borderRight: '1px solid #ccc' }}>
          {index === editedDoorConfig.length - 1 ? (
            // Last row: Display as non-editable
            door[field]
          ) : field === 'category' ? (
            // Display category as a non-editable field
            door[field]
          ) : field === 'selections' ? (
            // Editable dropdown for 'selections' column only
            isEditing ? (
              <FormControl fullWidth>
                <Select
                  value={door[field] || ''} // Ensure value is initially the database value or an empty string
                  onChange={(e) => handleDoorConfigEdit(index, field, e.target.value)}
                >
                  {(rowSpecificOptions[index] || []).map((option, optionIdx) => (
                    <MenuItem key={optionIdx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              // Display value directly if not editing
              door[field]
            )
          ) : isEditing ? (
            // Editable field for other columns
            <TextField
              value={door[field]}
              onChange={(e) => handleDoorConfigEdit(index, field, e.target.value)}
              fullWidth
            />
          ) : (
            // Display value directly if not editing
            door[field]
          )}
        </TableCell>
      ))}
    </TableRow>
  ))}
</TableBody>

                </Table>

                {isEditing && (
                  <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={handleSaveClick} variant="contained" color="primary" style={{ marginRight: '10px' }}>Save Changes</Button>
                    <Button onClick={handleCancelEdit} variant="outlined" color="secondary">Cancel Changes</Button>
                  </Box>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTable;
