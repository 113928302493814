import React, { useEffect } from "react";
import "./App.css";
import { ColorProvider } from "./componets/ColorContext";
import MiniDrawer from "./componets/MiniDrawer";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Userdata from "./componets/Userdetails/usersData";
import UserTable from "./componets/Userdetails/UserTable";
import EditDoorConfig from "./componets/Userdetails/EditDoorConfig";
 
function App() {
  useEffect(() => {
    // Clear localStorage when the App component mounts (on page load)
    localStorage.clear();
  }, []);
  return (
    <ColorProvider>
      <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element={ <MiniDrawer />} />
      <Route path="/Userdata" element={ <Userdata/>} />
      <Route path="/UserTable" element={ <UserTable/>} />
      <Route path="/edit-door-config/:userId" element={<EditDoorConfig />} />

         </Routes>
        </BrowserRouter>
      </div>
    </ColorProvider>
  );
}
//Ayush
export default App;
