// Import the required functions from Firebase SDK
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, update } from 'firebase/database';  // Import Realtime Database methods
import { getAuth } from 'firebase/auth';  // Import Firebase Authentication methods

// Firebase configuration object (you already have this)
const firebaseConfig = {
  apiKey: "AIzaSyBz0ljbtBj0Lpv6xH0_3rfCBtyj0kGDzcg",
  authDomain: "osl-3d.firebaseapp.com",
  databaseURL: "https://osl-3d-default-rtdb.firebaseio.com",
  projectId: "osl-3d",
  storageBucket: "osl-3d.firebasestorage.app",
  messagingSenderId: "834590066483",
  appId: "1:834590066483:web:ff9ebb774333efcbe9a046",
  measurementId: "G-2X0ES068QC"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and Authentication
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };
