import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

import { useColorContext } from "../ColorContext";


import { db } from '../Enquiry/firebase'; // Firebase configuration
import { ref, get, update } from 'firebase/database';  // Importing 'update' from Firebase Realtime Database
import { useNavigate, useParams } from 'react-router-dom'; // To get userId from the URL

interface DoorConfig {
  unitCost?: number;
  [key: string]: any;
}



const KIT_COLOR = '#5F1C1D';
const CustomRadio = styled(Radio)({
  color: KIT_COLOR,
  '&.Mui-checked': {
    color: KIT_COLOR,
  },
});

const Micscom = ({ onSelect }: { onSelect: (component: React.ReactNode) => void }) => {
  const [trimexterior2UnitCost, setTrimexterior2UnitCost] = useState<any>(null); // Start with null
  const [trimexterior2UnitCost1, setTrimexterior2UnitCost1] = useState<any>(0); // Start with 0
  const [trimexterior2UnitCost2, setTrimexterior2UnitCost2] = useState<number>(140);
  const [trimexterior2UnitCost3, setTrimexterior2UnitCost3] = useState<number>(550);

  // Boolean state for showing input fields and radio button values
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showInput1, setShowInput1] = useState<boolean>(false);
  const [showInput2, setShowInput2] = useState<boolean>(false);
  const [showInput3, setShowInput3] = useState<boolean>(false);

  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [unitCost, setUnitCost] = useState<number | null>(null);
  const [area, setArea] = useState<number | null>(null);

  const { savedata} = useColorContext() as any;


  useEffect(() => {
    const storedWidth = localStorage.getItem('width');
    const storedHeight = localStorage.getItem('height');
    const storedUnitCost = localStorage.getItem('unitCost');

    if (storedWidth) setWidth(Number(storedWidth));
    if (storedHeight) setHeight(Number(storedHeight));
    if (storedUnitCost) setUnitCost(Number(storedUnitCost));
  }, []);

  useEffect(() => {
    if (width !== null && height !== null && unitCost !== null) {
      const calculatedArea = (((width * height) / 144) * unitCost); // Calculate area in square feet
      const areaValue = 0.2 * calculatedArea; // Calculate 20% of the area
      setArea(areaValue);

      if (trimexterior2UnitCost === '') {
        setTrimexterior2UnitCost(areaValue.toFixed(2)); // Update archunitcost with the calculated area
      } else if (trimexterior2UnitCost1 === '') {
        setTrimexterior2UnitCost1(areaValue.toFixed(2)); // Update archunitcost with the calculated area
      }
    }
  }, [width, height, unitCost, trimexterior2UnitCost, trimexterior2UnitCost1]);

 

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value === 'Yes';
    setShowInput(selectedValue);
    await AsyncStorage.setItem('overunit10', selectedValue.toString());

 
 



  };

  const handleRadioChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value === 'Yes';
    setShowInput1(selectedValue);
    await AsyncStorage.setItem('overunit14', selectedValue.toString());
 
 

  };
 




const estimatedCostString = localStorage.getItem("Estimatedcost");
const Estimatedcost = estimatedCostString ? JSON.parse(estimatedCostString) : 0; // Fallback to 0 if it's null
 




useEffect(() => {
  // Log values for debugging purposes 
  // Check if condition is met
  if (
    trimexterior2UnitCost == null ||  // Check for null
    trimexterior2UnitCost === 0 ||    // Check for 0
    trimexterior2UnitCost === '' ||  // Check for empty string
    trimexterior2UnitCost === "null" // Check for the string "null"
  ) {
 
    setTrimexterior2UnitCost(Estimatedcost);
  }

  if (
    trimexterior2UnitCost1 == null || // Check for null
    trimexterior2UnitCost1 === 0 ||   // Check for 0
    trimexterior2UnitCost1 === ''     // Check for empty string
  ) {
     
    setTrimexterior2UnitCost1(Estimatedcost);
  }

}, [Estimatedcost, trimexterior2UnitCost, trimexterior2UnitCost1]);




localStorage.setItem("Over10Door10",trimexterior2UnitCost  );
localStorage.setItem("Over14Door14",trimexterior2UnitCost1  );





 







  const handleRadioChange2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value === 'Yes';
    setShowInput2(selectedValue);
    await AsyncStorage.setItem('bugScreen', selectedValue.toString());
  };

  const handleRadioChange3 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value === 'Yes';
    setShowInput3(selectedValue);
    await AsyncStorage.setItem('Remodal', selectedValue.toString());
  };

  const handleTrimexterior2UnitCostChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const overvalue10 = event.target.value;
    setTrimexterior2UnitCost(parseFloat(event.target.value));
    await AsyncStorage.setItem('trimexterior2UnitCostMics', overvalue10);
  };

  const handleTrimexterior2UnitCostChange1 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const overunit14unitcost = event.target.value;
    setTrimexterior2UnitCost1(parseFloat(event.target.value));
    await AsyncStorage.setItem('overunit14unitcost', overunit14unitcost);
  };

  const handleTrimexterior2UnitCostChange2 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const BugScreenUnitCost = event.target.value;
    setTrimexterior2UnitCost2(parseFloat(event.target.value));
    await AsyncStorage.setItem('BugScreenUnitCost', BugScreenUnitCost);
  };

  const handleTrimexterior2UnitCostChange3 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const RemoldUnitCost = event.target.value;
    setTrimexterior2UnitCost3(parseFloat(event.target.value));
    await AsyncStorage.setItem('RemoldUnitCost', RemoldUnitCost);
  };

  useEffect(() => {
    const loadValues = async () => {
      const overunit10 = await AsyncStorage.getItem('overunit10');
      const overunit14 = await AsyncStorage.getItem('overunit14');
      const bugScreen = await AsyncStorage.getItem('bugScreen');
      const Remodal = await AsyncStorage.getItem('Remodal');
      const trimexterior2UnitCost = await AsyncStorage.getItem('trimexterior2UnitCostMics');
      const overunit14unitcost = await AsyncStorage.getItem('overunit14unitcost');
      const BugScreenUnitCost = await AsyncStorage.getItem('BugScreenUnitCost');
      const RemoldUnitCost = await AsyncStorage.getItem('RemoldUnitCost');
  
      // Set boolean values
      if (overunit10) setShowInput(overunit10 === 'true');
      if (overunit14) setShowInput1(overunit14 === 'true');
      if (bugScreen) setShowInput2(bugScreen === 'true');
      if (Remodal) setShowInput3(Remodal === 'true');
  
      // Set numeric values, converting strings to numbers
      if (trimexterior2UnitCost) setTrimexterior2UnitCost(parseFloat(trimexterior2UnitCost));
      if (overunit14unitcost) setTrimexterior2UnitCost1(parseFloat(overunit14unitcost));
      if (BugScreenUnitCost) setTrimexterior2UnitCost2(parseFloat(BugScreenUnitCost));
      if (RemoldUnitCost) setTrimexterior2UnitCost3(parseFloat(RemoldUnitCost));
    };
  
    loadValues();
  }, []);
  


  
    
  
    //////////////////////   firebase start   /////////
  
  
  
  
  
  
    useEffect(() => {
  
   
  
      setEditedConfig((prev) => ({ ...prev, seselections: showInput })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, unitCost: trimexterior2UnitCost })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, estimatedCost: trimexterior2UnitCost })); // Update the editedConfig
      setEditedConfig((prev) => ({ ...prev, price: trimexterior2UnitCost * 2 })); // Update the editedConfig
  
      setEditedConfig2((prev) => ({ ...prev, selections: showInput1 })); // Update the editedConfig
      setEditedConfig2((prev) => ({ ...prev, unitCost: trimexterior2UnitCost1 })); // Update the editedConfig
      setEditedConfig2((prev) => ({ ...prev, estimatedCost: trimexterior2UnitCost1 })); // Update the editedConfig
      setEditedConfig2((prev) => ({ ...prev, price: trimexterior2UnitCost1 *2 })); // Update the editedConfig
  
      setEditedConfig3((prev) => ({ ...prev, selections: showInput2 })); // Update the editedConfig
      setEditedConfig3((prev) => ({ ...prev, unitCost: trimexterior2UnitCost2 })); // Update the editedConfig
      setEditedConfig3((prev) => ({ ...prev, estimatedCost: trimexterior2UnitCost2 })); // Update the editedConfig
      setEditedConfig3((prev) => ({ ...prev, price: trimexterior2UnitCost2 *2.5})); // Update the editedConfig
  
      setEditedConfig4((prev) => ({ ...prev, selections: showInput3 })); // Update the editedConfig
      setEditedConfig4((prev) => ({ ...prev, unitCost: trimexterior2UnitCost3 })); // Update the editedConfig
      setEditedConfig4((prev) => ({ ...prev, estimatedCost: trimexterior2UnitCost3 })); // Update the editedConfig
      setEditedConfig4((prev) => ({ ...prev, price: trimexterior2UnitCost3*2 })); // Update the editedConfig
   
       
  
    
    }, [ showInput,showInput1,showInput2,showInput3 ,trimexterior2UnitCost ,trimexterior2UnitCost1,trimexterior2UnitCost2,trimexterior2UnitCost3]);
  
  
  
  
  
  
  
  
    const [configIndex, setConfigIndex] = useState<number>(0);
    const [doorConfigs, setDoorConfigs] = useState([]); // State to hold all door configurations
    const [editedConfig, setEditedConfig] = useState<DoorConfig>({});
    const [editedConfig2, setEditedConfig2] = useState<DoorConfig>({});
    const [editedConfig3, setEditedConfig3] = useState<DoorConfig>({});
    const [editedConfig4, setEditedConfig4] = useState<DoorConfig>({});
    const navigate = useNavigate(); // For navigation after save
  
  
  
  
  
    const userId = savedata;
  
    // Fetch the door config of the selected user from Firebase
    useEffect(() => {
      const userRef = ref(db, `users/${userId}`);
      get(userRef).then((snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.doorConfig) {
          setDoorConfigs(userData.doorConfig);
          setEditedConfig(userData.doorConfig[8] || {}); // Initialize the 0th door config
          setEditedConfig2(userData.doorConfig[9] || {}); // Initialize the 2nd door config
          setEditedConfig3(userData.doorConfig[19] || {}); // Initialize the 2nd door config
          setEditedConfig4(userData.doorConfig[22] || {}); // Initialize the 2nd door config
        }
      }).catch((error) => {
        alert('Failed to load door configuration');
      });
    }, [userId]);
  
   
  
  
  
    const handleSave = () => {
      const userRef = ref(db, `users/${userId}/doorConfig`);
  
      // Create an updates object with string keys for doorConfig
      const updates: { [key: string]: DoorConfig } = {};
      updates["8"] = editedConfig;  // Use string keys for doorConfig
      updates["9"] = editedConfig2;
      updates["19"] = editedConfig3;
      updates["22"] = editedConfig3;
  
  
      // Perform the update in Firebase
      update(userRef, updates)
        .then(() => {
          alert('Door configurations updated successfully!');
          // navigate('/'); // Navigate back to the main page
        })
        .catch((error) => {
          alert('Failed to update door configuration');
        });
    };
  
  
  
  
  
    // setWidth(editedConfig.width) 
  
  
  
  
  
    useEffect(() => {
  
      if(userId){
      if (editedConfig) {
  
         if(  showInput === false  )  { setShowInput(editedConfig.selections  || false );}
         if(trimexterior2UnitCost === '0'  || trimexterior2UnitCost === ''  ) { setTrimexterior2UnitCost(editedConfig.unitCost || '0' );}
      
       }
  
      if (editedConfig2) {
  
        if(  showInput1 === false  )  { setShowInput1(editedConfig2.selections  || false );}
        if(trimexterior2UnitCost1 === '0'  || trimexterior2UnitCost1 === ''  ) { setTrimexterior2UnitCost1(editedConfig2.unitCost || '0' );}
     
      }
 
      if (editedConfig3) {
  
        if(  showInput2 === false  )  { setShowInput2(editedConfig3.selections  || false );}
        if(trimexterior2UnitCost2 === 140  || trimexterior2UnitCost2 ===  null  ) { setTrimexterior2UnitCost2(editedConfig3.unitCost || 140  );}
     
      }

      if (editedConfig4) {
  
        if(  showInput3 === false  )  { setShowInput3(editedConfig4.selections  || false );}
        if(trimexterior2UnitCost3 === 550  || trimexterior2UnitCost3 === null  ) { setTrimexterior2UnitCost3(editedConfig4.unitCost || 550 );}
     
      }
    }
      
    }, [editedConfig, editedConfig2,showInput, showInput1,showInput2,showInput3,trimexterior2UnitCost,trimexterior2UnitCost1 ,trimexterior2UnitCost2,trimexterior2UnitCost3, ]);
    
   
  
    /////////////////////   firebase end   ///////////////
  
  







  return (
    <div>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
          Misc
        </Typography>
        <Typography
          variant="h6"
          sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
        >
          Over 10 Door
        </Typography>

        <FormControl sx={{ pl: 2 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange}
            value={showInput ? 'Yes' : 'No'}
          >
            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
            <FormControlLabel value="No" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
        <br />

        {showInput && (
          <TextField
            label="Unit Cost"
            value={trimexterior2UnitCost}
            onChange={handleTrimexterior2UnitCostChange}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: '1' }}
            style={{ width: '100%' }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
          />
        )}

        <Typography
          variant="h6"
          sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
        >
          Over 14 Unit
        </Typography>

        <FormControl sx={{ pl: 2 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange1}
            value={showInput1 ? 'Yes' : 'No'}
          >
            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
            <FormControlLabel value="No" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
        <br />

        {showInput1 && (
          <TextField
            label="Unit Cost"
            value={trimexterior2UnitCost1}
            onChange={handleTrimexterior2UnitCostChange1}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: '1' }}
            style={{ width: '100%' }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
          />
        )}

        <Typography
          variant="h6"
          sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
        >
          Bug Screen
        </Typography>

        <FormControl sx={{ pl: 2 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange2}
            value={showInput2 ? 'Yes' : 'No'}
          >
            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
            <FormControlLabel value="No" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
        <br />

        {showInput2 && (
          <TextField
            label="Unit Cost"
            value={trimexterior2UnitCost2}
            onChange={handleTrimexterior2UnitCostChange2}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: '1' }}
            style={{ width: '100%' }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
          />
        )}

        <Typography
          variant="h6"
          sx={{ backgroundColor: '#5F1C1D', color: 'white', pl: 2, pt: 0.5, pb: 0.5, mt: 2, mb: 2 }}
        >
          Remodal
        </Typography>

        <FormControl sx={{ pl: 2 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange3}
            value={showInput3 ? 'Yes' : 'No'}
          >
            <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
            <FormControlLabel value="No" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
        <br />

        {showInput3 && (
          <TextField
            label="Unit Cost"
            value={trimexterior2UnitCost3}
            onChange={handleTrimexterior2UnitCostChange3}
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            inputProps={{ step: '1' }}
            style={{ width: '100%' }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: KIT_COLOR, // Default border color
                },
                '&:hover fieldset': {
                  borderColor: KIT_COLOR, // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: KIT_COLOR, // Border color when focused
                },
              },
              '& .MuiInputLabel-root': {
                color: KIT_COLOR, // Label color
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: KIT_COLOR, // Label color when focused
              },
            }}
          />
        )}
      </Box>

      <br />
      <br/>
      {savedata ?
      
      

<button style={{width:"100%",display:'flex',justifyContent:"center",backgroundColor:"rgb(95, 28, 29)"}} onClick={handleSave}>Save Changes</button>


:null}
<br />
<br />
   
    </div>
  );
};

export default Micscom;
